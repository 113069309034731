import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import Utils from "~/common/utils/store";
/**
 * Tracking store
 *
 * register methods in your components with import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
 *
 * pick only the method you need
 *
   methods :{
  ...mapActions({
      loadTrackings:"tracking/LOAD_TRACKINGS",
      countTrackings:"user/COUNT_TRACKINGS",
      exportTrackings:"tracking/EXPORT_TRACKINGS",
    }),
    ...mapMutations({
      setTrackings:"tracking/SET_TRACKINGS",
      setTrackingsCount:"user/SET_TRACKINGS_COUNT",
      resetStateTracking:"tracking/RESET_STATE",
    }),
    },

      computed: {
    ...mapState("tracking", ["trackings","trackingsCount"]),
    ...mapGetters("tracking", []),
      }
 */



const getDefaultState = () => {
  return {
    trackings: [],
    trackingsCount: 0
  }
}

// initial state
const state = getDefaultState;

// getters are functions
const getters = {};

// mutations
const mutations = {
  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {Object} trackings
   *
   * set collections data trackings
   */
  SET_TRACKINGS: (state, trackings) => {
    state.trackings = trackings;
  },

  /**
 * @param {Object} state - https://vuex.vuejs.org/guide/state.html
 * @param {Object} payload - { count }
 *
 * set count data trackings
 */
  SET_TRACKINGS_COUNT: (state, { count }) => {
    state.trackingsCount = count;
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state to default value
   */
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  }

};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  /**
   * fetch collection of trackings
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  LOAD_TRACKINGS: async function (context, payload) {

    return this.$axios.get('/api/v1/tracking/list?filter=' + JSON.stringify(payload.filter) + '&sort=' + payload.sort + '&limit=' + payload.limit + '&skip=' + payload.skip)
      .then(r => r.data)
      .then(
        trackings => {
          this.commit("tracking/SET_TRACKINGS", trackings);
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'TRACKINGS are loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
  * fetch number of trackings
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {String} query - see more https://github.com/loris/api-query-params
  * @return Promise
  */
  COUNT_TRACKINGS: async function (context, payload) {
    return this.$axios
      .get('/api/v1/tracking/count?filter=' + JSON.stringify(payload.filter))
      .then(r => r.data)
      .then(
        count => {
          this.commit('tracking/SET_TRACKINGS_COUNT', { count });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'COUNT TRACKINGS are loaded' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

          return false;
        }
      );
  },

  /**
   * export collection of trackings
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more
   * @return Promise
   */
  EXPORT_TRACKINGS: async function (context, payload) {
    return this.$axios
      .get('/api/v1/tracking/export?filter=' + JSON.stringify(payload.filter) + '&sort=' + payload.sort)
      .then(r => r.data)
      .then(
        data => {
          this.dispatch('api/API_SUCCESS', data);
          return data;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  }

};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};



export default store;
