<template>
  <div
    v-bind:class="{
      notification: true,
      'is-unread': notification.read === false,
    }"
  >
    <el-row type="flex">
      <el-col :span="4" v-if="hasImage">
        <span v-if="image && image.length > 0">
          <img :src="image" class="notification-image" />
        </span>
        <MzMagazineThumb
          v-else-if="magazine"
          :magazine="magazine"
          class="notification-magazine"
          @click.native="onClickMagazine"
        />
        <span v-else>
          <img src="/logo-madmagz-small.svg" class="notification-logo" />
        </span>
      </el-col>
      <el-col :span="hasImage ? 20 : 24">
        <div>
          <div class="time">
            {{ displayTime }}
            <div v-if="notification.read === false" class="unread-dot" />
          </div>
          <span class="magazineName" v-if="magazine">
            {{ `${magazine.name}, ${magazine.issue}` }}<br />
          </span>
          <span class="title" v-html="title"></span>
        </div>

        <div class="message" v-html="message"></div>
        <div class="actions" v-if="hasActions">
          <el-button
            v-if="primaryAction.go2Params"
            type="primary"
            size="small"
            @click="goto(primaryAction.go2Params)"
            >{{ primaryAction.label }}</el-button
          >
          <el-button
            v-if="secondaryAction.go2Params"
            size="small"
            @click="goto(secondaryAction.go2Params)"
            >{{ secondaryAction.label }}</el-button
          >
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { NotificationTypes } from "~/common/data/NotificationTypes";
import MzMagazineThumb from "~/components/magazine/MzMagazineThumb";

export default {
  name: "Notification",
  components: { MzMagazineThumb },

  props: ["notification"],

  data() {
    return {
      image: null,
      message: "",
      title: "",
      primaryAction: {
        label: "",
        go2Params: null,
      },
      secondaryAction: {
        label: "",
        go2Params: null,
      },
    };
  },

  computed: {
    magazine() {
      return this.notification &&
        this.notification.data &&
        this.notification.data.magazine
        ? this.notification.data.magazine
        : null;
    },
    hasImage() {
      return (
        this.magazine !== null ||
        this.notification.type === NotificationTypes.MzScheduledNotification
      );
    },
    hasActions() {
      return (
        this.primaryAction.go2Params !== null ||
        this.secondaryAction.go2Params !== null
      );
    },
    displayTime() {
      let createdAt = new Date(this.notification.createdAt).getTime(),
        now = this.$now.time,
        elapse = Math.round((now - createdAt) / 1000);

      if (elapse <= 2 * 60) {
        // 2 min
        return this.$t("public.notofications.elaspedTime.now");
      } else if (elapse <= 60 * 60) {
        // 1 hour
        return this.$t("public.notofications.elaspedTime.minutes", {
          elapse: Math.round(elapse / 60),
        });
      } else if (elapse <= 24 * 60 * 60) {
        // 1 day
        return this.$t("public.notofications.elaspedTime.hours", {
          elapse: Math.round(elapse / (60 * 60)),
        });
      } else if (elapse <= 7 * 24 * 60 * 60) {
        // 1 semaine
        return this.$t("public.notofications.elaspedTime.days", {
          elapse: Math.round(elapse / (24 * 60 * 60)),
        });
      } else {
        return this.$t("public.notofications.elaspedTime.weeks", {
          elapse: Math.round(elapse / (7 * 24 * 60 * 60)),
        });
      }
    },
  },

  created() {
    this.extractDataFromNotification();
  },
  methods: {
    goto(params) {
      this.$bus.$emit("hide_client_popover");
      return this.$go2Route(params);
    },

    onClickMagazine() {
      if (this.magazine && !this.magazine.deleted) {
        if (this.magazine.status === "Published") {
          this.goto({
            name: "client-magazine-edit-magazineId-publication",
            params: {
              magazineId: this.notification.data.magazineId,
            },
          });
        } else {
          this.goto({
            name: "client-magazine-edit-magazineId",
            params: {
              magazineId: this.notification.data.magazineId,
            },
          });
        }
      }
    },

    extractDataFromNotification() {
      this.image = null;
      this.message = "";
      this.title = "";
      this.primaryAction.label = "";
      this.primaryAction.go2Params = null;
      this.secondaryAction.label = "";
      this.secondaryAction.go2Params = null;

      if (!this.notification) {
        return;
      }

      switch (this.notification.type) {
        case NotificationTypes.MzScheduledNotification:
          if (
            this.notification.data &&
            this.notification.data.scheduledNotification
          ) {
            let scheduledNotificationData =
              this.notification.data.scheduledNotification;
            this.message = scheduledNotificationData.description;
            this.title = scheduledNotificationData.title;

            this.image =
              scheduledNotificationData.visualUrl &&
              scheduledNotificationData.visualUrl.length > 0
                ? scheduledNotificationData.visualUrl
                : null;

            if (
              scheduledNotificationData.primaryAction &&
              scheduledNotificationData.primaryAction.label &&
              scheduledNotificationData.primaryAction.label.length > 0 &&
              scheduledNotificationData.primaryAction.url &&
              scheduledNotificationData.primaryAction.url.length > 0
            ) {
              this.primaryAction.label =
                scheduledNotificationData.primaryAction.label;
              this.primaryAction.go2Params = {
                target: "_blank",
                url: scheduledNotificationData.primaryAction.url,
              };
            }

            if (
              scheduledNotificationData.secondaryAction &&
              scheduledNotificationData.secondaryAction.label &&
              scheduledNotificationData.secondaryAction.label.length > 0 &&
              scheduledNotificationData.secondaryAction.url &&
              scheduledNotificationData.secondaryAction.url.length > 0
            ) {
              this.secondaryAction.label =
                scheduledNotificationData.secondaryAction.label;
              this.secondaryAction.go2Params = {
                target: "_blank",
                url: scheduledNotificationData.secondaryAction.url,
              };
            }
          }
          break;

        case NotificationTypes.PublishedMagazine:
          this.message = this.$t(
            this.notification.data.isOwner
              ? "public.notifications.publishedMagazine.ownerMessage"
              : "public.notifications.publishedMagazine.message",
            this.notification.data
          );
          this.title = this.$t(
            "public.notifications.publishedMagazine.title",
            this.notification.data
          );
          this.primaryAction.label = this.$t(
            "public.notifications.publishedMagazine.primaryAction"
          );
          this.primaryAction.go2Params = {
            target: "_blank",
            name: "viewer-magazineId",
            params: {
              magazineId: this.notification.data.magazineId,
            },
          };

          if (this.notification.data.isOwner) {
            this.secondaryAction.label = this.$t(
              "public.notifications.publishedMagazine.secondaryAction"
            );
            this.secondaryAction.go2Params = {
              name: "client-magazine-edit-magazineId-publication",
              params: {
                magazineId: this.notification.data.magazineId,
              },
            };
          }
          break;

        case NotificationTypes.DeletedMagazine:
          this.message = this.$t(
            this.notification.data.isAutoDelete
              ? "public.notifications.deletedMagazine.autoMessage"
              : "public.notifications.deletedMagazine.message",
            this.notification.data
          );
          this.title = this.$t(
            "public.notifications.deletedMagazine.title",
            this.notification.data
          );
          break;

        case NotificationTypes.WarningDeletedMagazine:
          this.message = this.$t(
            "public.notifications.warningDeletedMagazine.message",
            this.notification.data
          );
          this.title = this.$t(
            "public.notifications.warningDeletedMagazine.title",
            this.notification.data
          );
          break;

        case NotificationTypes.FirstAccessMagazine:
          this.message = this.$t(
            "public.notifications.firstAccessMagazine.message",
            this.notification.data
          );
          this.title = this.$t(
            "public.notifications.firstAccessMagazine.title",
            this.notification.data
          );
          break;

        case NotificationTypes.InvitCollaboration:
          this.message = this.$t(
            "public.notifications.invitCollaboration.message",
            {
              ...this.notification.data,
              role: this.$t(
                "public.collaboration.roles." +
                  this.notification.data.collaboratorRole
              ),
            }
          );
          this.title = this.$t(
            "public.notifications.invitCollaboration.title",
            this.notification.data
          );

          this.primaryAction.label = this.$t(
            "public.notifications.invitCollaboration.primaryAction"
          );
          this.primaryAction.go2Params = {
            name: "client-magazine-edit-magazineId",
            params: {
              magazineId: this.notification.data.magazineId,
            },
          };
          break;

        case NotificationTypes.RevokeCollaboration:
          this.message = this.$t(
            "public.notifications.revokeCollaboration.message",
            this.notification.data
          );
          this.title = this.$t(
            "public.notifications.revokeCollaboration.title",
            this.notification.data
          );
          break;

        case NotificationTypes.ChangeCollaborationRole:
          this.message = this.$t(
            "public.notifications.changeCollaborationRole.message",
            {
              ...this.notification.data,
              role: this.$t(
                "public.collaboration.roles." +
                  this.notification.data.collaboratorRole
              ),
            }
          );
          this.title = this.$t(
            "public.notifications.changeCollaborationRole.title",
            this.notification.data
          );

          this.primaryAction.label = this.$t(
            "public.notifications.learnMoreAction"
          );
          this.primaryAction.go2Params = {
            target: "_blank",
            url: this.$t("public.notifications.changeCollaborationRole.linkTo"),
          };
          break;

        case NotificationTypes.UpdateCollaborationSettings:
          let msgKey = "";
          if (this.notification.data.associateSettings.viewFlatplan) {
            msgKey =
              "viewFlatplan." +
              this.notification.data.associateSettings.viewFlatplan;
          } else if (this.notification.data.associateSettings.editFlatplan) {
            msgKey =
              "editFlatplan." +
              this.notification.data.associateSettings.editFlatplan;
          } else if (this.notification.data.associateSettings.assignToAnother) {
            msgKey =
              "assignToAnother." +
              this.notification.data.associateSettings.assignToAnother;
          }

          this.message = this.$t(
            "public.notifications.updateCollaborationSettings.message." +
              msgKey,
            this.notification.data
          );
          this.title = this.$t(
            "public.notifications.updateCollaborationSettings.title." + msgKey,
            this.notification.data
          );

          this.primaryAction.label = this.$t(
            "public.notifications.learnMoreAction"
          );
          this.primaryAction.go2Params = {
            target: "_blank",
            url: this.$t(
              "public.notifications.updateCollaborationSettings.linkTo"
            ),
          };
          break;

        case NotificationTypes.AssignPages:
        case NotificationTypes.TakeBackPages:
          let isAssignType =
              this.notification.type === NotificationTypes.AssignPages,
            nbPages = 0,
            pageList = "";
          if (
            this.notification.data.assignedPages &&
            this.notification.data.assignedPages.length > 0
          ) {
            nbPages = this.notification.data.assignedPages.length;
            pageList = this.notification.data.assignedPages
              .map((page) =>
                page.pageName
                  ? page.pageName
                  : this.$t(
                      page.double
                        ? "public.edit_magazine.double_page_name"
                        : "public.edit_magazine.page_name",
                      {
                        number: page.pageNumber,
                      }
                    )
              )
              .join(", ");
          }

          this.message = this.$tc(
            isAssignType
              ? "public.notifications.assignPages.message"
              : "public.notifications.takeBackPages.message",
            nbPages,
            {
              ...this.notification.data,
              nbPages,
              pageList,
            }
          );
          this.title = this.$tc(
            isAssignType
              ? "public.notifications.assignPages.title"
              : "public.notifications.takeBackPages.title",
            nbPages,
            {
              ...this.notification.data,
              nbPages,
            }
          );

          if (isAssignType) {
            this.primaryAction.label = this.$t(
              "public.notifications.assignPages.primaryAction"
            );
            this.primaryAction.go2Params = {
              name: "client-magazine-edit-magazineId",
              params: {
                magazineId: this.notification.data.magazineId,
              },
            };
          }
          break;

        case NotificationTypes.OrderMagazineOfferConfirmed:
          this.message = this.$t(
            "public.notifications.orderMagazineOfferConfirmed.message",
            this.notification.data
          );
          this.title = this.$t(
            "public.notifications.orderMagazineOfferConfirmed.title",
            this.notification.data
          );
          break;

        case NotificationTypes.OrderMagazinePrintConfirmed:
          this.message = this.$t(
            "public.notifications.orderMagazinePrintConfirmed.message",
            this.notification.data
          );
          this.title = this.$t(
            "public.notifications.orderMagazinePrintConfirmed.title",
            this.notification.data
          );
          break;

        case NotificationTypes.OrderMagazinePrintShipped:
          this.message = this.$t(
            "public.notifications.orderMagazinePrintShipped.message",
            this.notification.data
          );
          this.title = this.$t(
            "public.notifications.orderMagazinePrintShipped.title",
            this.notification.data
          );
          break;

        case NotificationTypes.OrderMagazinePrintDelivered:
          this.message = this.$t(
            "public.notifications.orderMagazinePrintDelivered.message",
            this.notification.data
          );
          this.title = this.$t(
            "public.notifications.orderMagazinePrintDelivered.title",
            this.notification.data
          );
          break;

        case NotificationTypes.DuplicatedMagazine:
          if (this.notification.data.duplicateInOtherLang) {
            this.message = this.$t(
              "public.notifications.message.duplicatedMagazineInLang",
              {
                magazineName: this.notification.data.magazineNameFrom,
                lang: this.$t(
                  "public.lang." + this.notification.data.duplicateInOtherLang
                ),
              }
            );
            this.title = this.$t(
              "public.notifications.title.duplicatedMagazineInLang",
              {
                magazineName: this.notification.data.magazineNameFrom,
                lang: this.$t(
                  "public.lang." + this.notification.data.duplicateInOtherLang
                ),
              }
            );

            this.primaryAction.label = this.$t(
              "public.notifications.duplicatedMagazineInLang.primaryAction"
            );
            this.primaryAction.go2Params = {
              name: "client-magazine-edit-magazineId",
              params: {
                magazineId: this.notification.data.magazineId,
              },
            };
          } else {
            this.message = this.$t(
              "public.notifications.message.duplicatedMagazine",
              {
                magazineName: this.notification.data.magazineName,
              }
            );
            this.title = this.$t(
              "public.notifications.title.duplicatedMagazine",
              {
                magazineName: this.notification.data.magazineName,
              }
            );
          }

          break;

        case NotificationTypes.WorkspaceExportMagazinesZipComplete:
          this.message = this.$t(
            "public.notifications.message.workspaceExportMagazinesZipComplete",
            {
              zipVersion: this.notification.data.zipVersion,
              workspaceName: this.notification.data.workspaceName,
            }
          );
          this.title = this.$t(
            "public.notifications.title.workspaceExportMagazinesZipComplete",
            {
              zipVersion: this.notification.data.zipVersion,
              workspaceName: this.notification.data.workspaceName,
            }
          );
          break;

        default:
          this.message = "---";
          this.title = "Unknow notification type";
          break;
      }
    },
  },

  watch: {
    notification(value) {
      this.extractDataFromNotification();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~assets/css/mz-variables.scss";

.notification {
  padding: 1rem;
  border-bottom: 1px solid #dcdfe6;
  word-break: normal;

  &.is-unread {
    background-color: #edf3ff;
  }
}

.time {
  float: right;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  color: $public-color-grey-1;
  margin-left: 1rem;
  margin-top: 2px;
}

.title {
  font-size: 14px;
  color: $public-color-black;
}

.magazineName {
  font-size: 14px;
  color: #6e6e6e;
}

.message {
  font-size: 13px;
  margin-top: 0.25rem;
}

.actions {
  margin-top: 0.75rem;
}

/deep/.el-button {
  font-size: 13px;
}

.unread-dot {
  margin-left: 0.5rem;
  background-color: $public-color-blue;
  width: 5px;
  height: 5px;
  border: 1px solid $public-color-blue;
  border-radius: 50%;
}

/deep/.blue {
  color: $public-color-blue;
}

.notification-magazine {
  cursor: pointer;
  & /deep/img {
    max-height: 75px;
  }
}

.notification-image {
  max-width: 75%;
  max-height: 100px;
}

.notification-logo {
  max-width: 50%;
  max-height: 100px;
}
</style>