<template>
  <img
    :key="pageId"
    :src="imgSrc"
    :draggable="false"
    @error="onImageLoadError"
    v-bind:class="{
      'page-very-big-size': type === 'very-big',
      'page-big-size': type === 'big',
      'page-big2-size': type === 'big2',
      'is-double-page': isDoublePage,
    }"
  />
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import ImageUtils from "~/common/utils/imageUtils";

export default {
  name: "MzPageImage",
  props: ["page", "type", "specificPalette"],
  data() {
    return {
      pageId: null,
      isPaletteDependant: false,
      isDoublePage: false,
      pageUpdatedAt: null,
      thumbData: null,
      isFirstLoadedTry: true,
    };
  },
  computed: {
    ...mapState("palette", ["palette"]),

    paletteForPage() {
      return this.specificPalette ? this.specificPalette : this.palette;
    },

    imgSrcError() {
      if (this.isDoublePage) {
        return this.type === "big" ||
          this.type === "very-big" ||
          this.type === "big2"
          ? "/placeholder/doublepage_big_size.svg"
          : "/placeholder/doublepage_normal_size.svg";
      } else {
        return this.type === "big" ||
          this.type === "very-big" ||
          this.type === "big2"
          ? "/placeholder/page_big_size.svg"
          : "/placeholder/page_normal_size.svg";
      }
    },

    imgSrc() {
      if (this.thumbData) {
        return this.thumbData;
      }

      if (!this.pageId) {
        return this.imgSrcError;
      }

      let width, height;
      if (this.type === "very-big") {
        width = this.isDoublePage ? 600 : 300;
        height = 420;
      } else if (this.type === "big2") {
        width = this.isDoublePage ? 330 : 165;
        height = 232;
      } else {
        width = this.isDoublePage ? 220 : 110;
        height = 155;
      }

      return ImageUtils.getImageResizedSrc(
        {
          key: ImageUtils.computePageKey(
            this.pageId,
            this.page.isPaletteDependant ? this.paletteForPage._id : null
          ),
          updatedAt: this.pageUpdatedAt ? this.pageUpdatedAt : undefined,
        },
        width,
        height,
        "inside"
      );
    },
  },
  mounted() {
    this.refresh(this.page);
  },
  beforeDestroy() {
    if (this.pageId) {
      this.$bus.$off(
        "thumb_page_change_" + this.pageId,
        this.onThumbImageChange
      );
    }
  },
  methods: {
    refresh(page_, forceUpdate_ = false) {
      this.isFirstLoadedTry = true;

      if (this.pageId) {
        this.$bus.$off(
          "thumb_page_change_" + this.pageId,
          this.onThumbImageChange
        );
      }

      if (typeof page_ === "object") {
        this.pageId = page_._id;
        this.isDoublePage = page_.double;
      } else {
        //TODO : try to not send only page id. Please send complete page object in MzPageImage
        this.pageId = page_;
        this.isDoublePage = false;
      }

      if (this.pageId) {
        if (forceUpdate_) {
          this.pageUpdatedAt = new Date();
        } else {
          this.pageUpdatedAt = page_.updatedAt;
        }

        this.$bus.$on(
          "thumb_page_change_" + this.pageId,
          this.onThumbImageChange
        );
      } else {
        this.pageUpdatedAt = null;
      }
    },
    onThumbImageChange(data) {
      if (data && data.thumbData) {
        // force refresh src before waiting to server send real png data update
        this.thumbData = data.thumbData;
      } else {
        this.thumbData = null;
        this.refresh(data && data.page ? data.page : this.page, true);
      }
    },
    onImageLoadError($event) {
      $event.target.src = this.imgSrcError;
      if (this.isFirstLoadedTry) {
        // force retry load if image is being copy on S3 in background
        this.isFirstLoadedTry = false;
        setTimeout(() => {
          this.pageUpdatedAt = new Date();
        }, 1000);
      }
    },
  },
  watch: {
    page(value) {
      this.refresh(value);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~assets/css/mz-variables.scss";

img {
  max-width: 64px;
  height: 81px;
  object-fit: contain;

  &.is-double-page {
    max-width: 128px;
  }

  &.page-big-size {
    max-width: 110px;
    height: 155px;

    &.is-double-page {
      max-width: 220px;
    }
  }

  &.page-big2-size {
    max-width: 165px;
    height: 232px;

    &.is-double-page {
      max-width: 330px;
    }
  }

  &.page-very-big-size {
    max-width: 300px;
    height: 420px;

    &.is-double-page {
      max-width: 600px;
    }
  }
}
</style>