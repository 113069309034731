<template>
  <el-row type="flex" align="left">
    <el-button
      type="primary"
      v-if="checkPerm('notification:scheduledCreate')"
      @click="$refs.editScheduledNotificationModal.showCreate()"
      >Schedule a notification</el-button
    >

    <EditScheduledNotificationModal ref="editScheduledNotificationModal" />
  </el-row>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import EditScheduledNotificationModal from "~/components/notifications/EditScheduledNotificationModal";

export default {
  name: "ScheduledNotificationsHeader",

  components: { EditScheduledNotificationModal },

  props: [],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("api", ["checkPerm"]),
  },

  methods: {},
};
</script>

<style scoped lang="scss">
</style>