export const NotificationTypes = {
  DuplicatedMagazine: "DUPLICATED_MAGAZINE",
  WorkspaceExportMagazinesZipComplete: "WORKSPACE_EXPORT_MAGAZINES_ZIP_COMPLETE",

  PublishedMagazine: "PUBLISHED_MAGAZINE",
  DeletedMagazine: "DELETED_MAGAZINE",
  WarningDeletedMagazine: "WARNING_DELETED_MAGAZINE",
  FirstAccessMagazine: "FIRST_ACCESS_MAGAZINE",
  InvitCollaboration: "INVIT_COLLABORATION",
  RevokeCollaboration: "REVOKE_COLLABORATION",
  ChangeCollaborationRole: "CHANGE_COLLABORATION_ROLE",
  AssignPages: "ASSIGN_PAGES",
  TakeBackPages: "TAKEBACK_PAGES",
  UpdateCollaborationSettings: "UPDATE_COLLABORATION_SETTINGS",

  OrderMagazineOfferConfirmed: "ORDER_MAGAZINE_OFFER_CONFIRMED",
  OrderMagazinePrintConfirmed: "ORDER_MAGAZINE_PRINT_CONFIRMED",
  OrderMagazinePrintShipped: "ORDER_MAGAZINE_PRINT_SHIPPED",
  OrderMagazinePrintDelivered: "ORDER_MAGAZINE_PRINT_DELIVERED",

  MzScheduledNotification: "MZ_SCHEDULED_NOTIFICATION"
}