var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-tooltip',{attrs:{"content":_vm.tooltip,"disabled":!_vm.tooltip,"placement":_vm.tooltipPlacement,"open-delay":500,"enterable":false}},[_c('el-button',{class:{
      'el-label-button': _vm.label,
      'el-big-button': _vm.type === 'big',
      'el-large-button': _vm.type === 'large',
      'el-medium-button': _vm.type === 'medium',
      'el-no-border-button': _vm.noBorder,
      'is-selected': _vm.selected,
    },attrs:{"circle":!_vm.label,"plain":_vm.noBorder !== true,"disabled":_vm.disabled},on:{"click":_vm.onClickEvent}},[(_vm.label)?_c('span',{staticClass:"span-label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),_c('img',{class:{ shake: _vm.animationShake },attrs:{"src":_vm.src}}),_vm._v(" "),(_vm.tag)?_c('el-tag',{class:{ 'el-tag-blue': _vm.tagType === 'blue' },attrs:{"size":"mini"}},[_vm._v(_vm._s(_vm.tag))]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }