<template>
  <div class="rte-element" v-if="textblock" v-loading="loading">
    <RichTextEditorActions
      class="rte-actionbar"
      v-if="editorIsReady && !backgroundProcess"
      :editor="editor"
      :textblock="textblock"
      :isAdminEditor="adminMode"
      @colorChanged="refreshTextColorContrast = true"
      @undo="onClickUndoText"
      @redo="onClickRedoText"
    />

    <el-row
      v-if="adminMode && textblock.type !== 'Footer' && !backgroundProcess"
      class="rte-text-generator"
      type="flex"
      justify="space-between"
      align="middle"
    >
      {{ $t("public.article_editor.text_generator.title") }}
      <el-button size="mini" type="info" @click="generateLoremIpsum('words')">{{
        $t("public.article_editor.text_generator.add_world")
      }}</el-button>
      <el-button
        size="mini"
        type="info"
        @click="generateLoremIpsum('sentences')"
        >{{
          $t("public.article_editor.text_generator.add_sentence")
        }}</el-button
      >
      <el-button
        size="mini"
        type="info"
        @click="generateLoremIpsum('paragraphs')"
        >{{
          $t("public.article_editor.text_generator.add_paragraph")
        }}</el-button
      >
      <el-button size="mini" type="info" @click="clearCurrentText">{{
        $t("public.article_editor.text_generator.clear")
      }}</el-button>
    </el-row>

    <div
      v-else-if="adminMode && textblock.type === 'Footer' && !backgroundProcess"
      class="rte-text-generator"
    >
      <el-row type="flex" justify="left" align="middle">
        <span class="mr-1">{{
          $t("public.article_editor.variable_generator.title")
        }}</span>
        <el-button size="mini" type="info" @click="generateVariable('page')">{{
          $t("public.article_editor.variable_generator.page")
        }}</el-button>
        <el-button size="mini" type="info" @click="generateVariable('nb')">{{
          $t("public.article_editor.variable_generator.nb")
        }}</el-button>
        <el-button size="mini" type="info" @click="generateVariable('name')">{{
          $t("public.article_editor.variable_generator.name")
        }}</el-button>
        <el-button size="mini" type="info" @click="generateVariable('issue')">{{
          $t("public.article_editor.variable_generator.issue")
        }}</el-button>
      </el-row>

      <el-row type="flex" justify="left" align="middle" class="mt-0">
        <span class="mr-1">Redactor Variables</span>
        <el-button size="mini" type="info" @click="generateVariable('var1')">
          Variable #1
        </el-button>
        <el-button size="mini" type="info" @click="generateVariable('var2')">
          Variable #2
        </el-button>
        <el-button size="mini" type="info" @click="generateVariable('var3')">
          Variable #3
        </el-button>
      </el-row>
    </div>

    <small v-if="title" class="mt-0 ml-2">{{ title }}</small>

    <div class="quill-editor-content">
      <quill-editor
        ref="editor"
        :disabled="backgroundProcess"
        :content="textContent"
        :style="quillEditorStyleVariables"
        @change="onEditorChange($event)"
        @ready="onEditorReady($event)"
        @focus="onEditorFocus()"
        @click.native="onEditorFocus()"
      ></quill-editor>
    </div>

    <el-alert v-show="textOverflow" :closable="false" type="error">
      <div
        slot="title"
        v-t="'public.article_editor.overflow_alert_title'"
      ></div>
      <div v-t="'public.article_editor.overflow_alert'"></div>
    </el-alert>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import FormatUtils from "~/utils/format";
import MiscUtils from "~/common/utils/misc";
import tinycolor from "tinycolor2";
import { loremIpsum } from "lorem-ipsum";
import RichTextEditorActions from "~/components/editor/RichTextEditorActions.vue";

export default {
  name: "RichTextEditor",
  components: {
    RichTextEditorActions,
  },
  props: ["textblock", "title", "backgroundProcess"],

  data() {
    return {
      loading: false,
      editorIsReady: false,
      lockEditorChangeSave: false,
      textOverflow: false,
      textContent: "",
      refreshTextColorContrast: false,
      lockSendSaveTextblock: false,
      resendSaveTextBlock: false,
      fabricStyleSheet: null,
    };
  },
  computed: {
    ...mapState("theme", ["theme"]),
    ...mapState("page", ["page"]),
    ...mapState("magazine", ["magazine"]),
    ...mapState("palette", [
      "palette",
      "themePalettes",
      "paletteDependantProperty",
    ]),
    ...mapGetters("app", ["adminMode"]),

    editor() {
      return this.$refs.editor.quill;
    },

    // usePaletteSwatches() {
    //   return this.palette._id !== null;
    // },

    quillEditorStyleVariables() {
      let styleVariable = {};
      const fabricTextbox =
        this.textblock.target && this.$fabric && this.$fabric.canvas
          ? this.$fabric.canvas.getObjectById(this.textblock.target)
          : null;

      const textLinkStyle = fabricTextbox
        ? fabricTextbox.getTextLinkStyle()
        : null;
      if (textLinkStyle) {
        Object.assign(styleVariable, {
          "--rte-link-color": textLinkStyle.fill,
          "--rte-link-text-decoration": textLinkStyle.underline
            ? "underline"
            : "",
          "--rte-link-font-weight": textLinkStyle.fontWeight,
          "--rte-link-font-style": textLinkStyle.fontStyle,
        });
      } else {
        Object.assign(styleVariable, {
          "--rte-link-text-decoration": "underline",
        });
      }

      const bulletPointStyle = fabricTextbox
        ? fabricTextbox.getBulletPointStyle()
        : null;
      if (bulletPointStyle) {
        Object.assign(styleVariable, {
          "--rte-list-color": bulletPointStyle.fill,
        });
      }
      const bulletPointCharacter = fabricTextbox
        ? fabricTextbox.getBulletPointCharacter()
        : null;
      if (bulletPointCharacter) {
        styleVariable["--rte-list-character"] =
          "'" + bulletPointCharacter + "'";
      }

      return styleVariable;
    },
  },
  mounted() {
    // create specific style sheet for fabric style by className
    this.fabricStyleSheet = document.createElement("style");
    this.fabricStyleSheet.type = "text/css";
    document.getElementsByTagName("head")[0].appendChild(this.fabricStyleSheet);

    let formatedStyles = this.theme.styles.reduce((list, s) => {
      if (
        (s.element === "header" || s.element === "paragraph") &&
        !s.hideInRTE
      ) {
        if (s.key) {
          const cssStyle = FormatUtils.fabricStyleToCss(
            s.style,
            this.palette.swatches,
            false
          );

          let formatedStyle = Object.keys(cssStyle).map((key) => {
            return key + ":" + cssStyle[key];
          });

          list.push({
            className: ".ql-container .ql-editor .fabric-style-" + s.key,
            cssStyle: formatedStyle.join(";"),
          });
        }
      }
      return list;
    }, []);

    for (let style of formatedStyles) {
      if (!(this.fabricStyleSheet.sheet || {}).insertRule) {
        (
          this.fabricStyleSheet.styleSheet || this.fabricStyleSheet.sheet
        ).addRule(style.className, style.cssStyle);
      } else {
        this.fabricStyleSheet.sheet.insertRule(
          style.className + "{" + style.cssStyle + "}",
          0
        );
      }
    }
  },
  beforeDestroy() {
    if (this.fabricStyleSheet) {
      this.fabricStyleSheet.parentNode.removeChild(this.fabricStyleSheet);
      this.fabricStyleSheet = null;
    }

    MiscUtils.executeDebounceNow(this, "debounceInput");
    MiscUtils.executeDebounceNow(this, "debounceSave");
    this.removeCanvasListener();
  },
  methods: {
    ...mapMutations({
      updateFabricStateField: "fabric/UPDATE_ROOT_FIELD",
      setText: "article/SET_TEXT",
      updateMagazineSettings: "magazine/UPDATE_SETTINGS",
    }),
    ...mapActions({
      saveTextblock: "article/SAVE_TEXTBLOCK",
      saveMagazine: "magazine/SAVE_MAGAZINE",
      saveTemplateMagazine: "magazine/SAVE_TEMPLATE_MAGAZINE",
      apiSendError: "api/API_SEND_ERROR",
      undoTextblock: "article/UNDO_TEXTBLOCK",
      redoTextblock: "article/REDO_TEXTBLOCK",
    }),

    updateText() {
      if (this.textblock.type === "Footer") {
        const extract = this.extractTextAndFlatStyle();
        // update magazine field
        this.updateMagazineSettings({
          key: "footer." + this.textblock.pagePosition,
          value: JSON.stringify({
            html: this.editor.root.innerHTML,
            ...extract,
          }),
        });

        // update fabric textboxes
        const textboxes = this.$fabric.canvas
          ? this.$fabric.canvas.getObjectsOfType("MzTextbox")
          : [];
        for (const fabricTextbox of textboxes) {
          if (fabricTextbox.blockType.indexOf("Footer") === 0) {
            if (this.textblock.pagePosition === "left") {
              fabricTextbox.setStyledTextIfLeftPage({
                text: extract.text,
                flatStyle: extract.flatStyle,
              });
            } else if (this.textblock.pagePosition === "right") {
              fabricTextbox.setStyledTextIfRightPage({
                text: extract.text,
                flatStyle: extract.flatStyle,
              });
            }
            this.$fabric.refresh(fabricTextbox);
          }
        }
      } else {
        let fabricTextbox =
          this.textblock.target && this.$fabric.canvas
            ? this.$fabric.canvas.getObjectById(this.textblock.target)
            : null;
        if (fabricTextbox) {
          const extract = this.extractTextAndFlatStyle();
          fabricTextbox.setStyledText({
            text: extract.text,
            flatStyle: extract.flatStyle,
          });
          this.$fabric.refresh(fabricTextbox);
        }
      }
    },

    extractTextAndFlatStyle() {
      const textLength = this.editor.getLength();

      // copy all characters style to an array to be sent to MzTextbox
      let lines = this.editor.getLines(0, textLength);
      let flatStyle = [];
      let lineChar = 0;
      let line = 0;
      let listIndex = 0;

      for (let char = 0; char < textLength; char++) {
        if (
          lines[line] &&
          lines[line].cache &&
          lines[line].cache.length === lineChar
        ) {
          line++;
          lineChar = 0;
        }
        let _style = FormatUtils.convertCsstoFabric(
          this.editor.getFormat(char, 1),
          lineChar === 0
        );
        if (_style && lineChar === 0) {
          // specific process for save list index on line style
          if (_style.list) {
            _style.listIndex = listIndex;
            listIndex++;
          } else {
            listIndex = 0;
          }
        }

        flatStyle.push(_style);
        lineChar++;
      }

      //remove last line jump added by quill before setting text to fabric
      const text = this.editor.getText().slice(0, -1);

      return { text, flatStyle };
    },
    initCanvasListener: function () {
      if (this.$fabric.canvas) {
        this.$fabric.canvas.on(
          "text:overflow",
          this.onFabricTextOverflowChanged
        );
      }
    },
    removeCanvasListener: function () {
      if (this.$fabric.canvas) {
        this.$fabric.canvas.off(
          "text:overflow",
          this.onFabricTextOverflowChanged
        );
      }
    },

    onFabricTextOverflowChanged(e, forceRemoveOverflowStyle = false) {
      const textLength = this.editor.getLength();
      if (forceRemoveOverflowStyle || this.textOverflow) {
        // remove previous overflow style
        this.editor.formatText(
          0,
          textLength,
          "overflowBackground",
          false,
          "silent"
        );
      }

      this.textOverflow = e.value;

      if (e.value && e.length > 0) {
        this.editor.formatText(
          textLength - e.length - 1,
          e.length,
          "overflowBackground",
          true,
          "silent"
        );
      }
    },

    onTextblockChanged() {
      // function called when user click undo or redo in RTE actions
      const previousCursorRange = this.editor.getSelection();

      this.refreshTextContent();

      this.$nextTick(() => {
        // will send text to fabric
        this.updateText();

        // will save text to server (from html set in state)
        if (this.textblock.type === "Footer") {
          if (this.magazine.isTemplate) {
            this.saveTemplateMagazine();
          } else {
            this.saveMagazine();
          }
        } else {
          if (this.textblock.target && !this.backgroundProcess) {
            this.$bus.$emit("rteTextHasChanged", this.textblock.target);
          }
        }

        // set cursor at the last known of text
        if (previousCursorRange) {
          this.editor.setSelection(previousCursorRange);
        }
      });
    },

    refreshTextContent(getFabricTextIfEmpty = false) {
      this.lockEditorChangeSave = true;
      this.refreshTextColorContrast = true;

      let _html = this.textblock.html;

      // THIS CODE MAKE BUG ON AMM between color 'rose' and 'saumon'
      // REMOVE IF NO NEW BUG WITH PALETTE REPORTED !!!
      // if (
      //   this.usePaletteSwatches &&
      //   this.themePalettes &&
      //   this.themePalettes.length > 0
      // ) {
      //   // override default color with current palette
      //   if (this.palette._id != this.themePalettes[0]._id) {
      //     for (let i = 0; i < this.themePalettes[0].swatches.length; i++) {
      //       const defaultSwatch = this.themePalettes[0].swatches[i],
      //         currentSwatch = this.palette.swatches[i];
      //       const defaultRgbColor = tinycolor(
      //           defaultSwatch.color
      //         ).toRgbString(),
      //         currentRgbColor = tinycolor(currentSwatch.color).toRgbString();
      //       if (defaultRgbColor !== currentRgbColor) {
      //         _html = _html.replace(
      //           new RegExp(
      //             defaultRgbColor
      //               .replace(/\(/g, "\\(")
      //               .replace(/\)/g, "\\)")
      //               .replace(/\./g, "\\."),
      //             "g"
      //           ),
      //           currentRgbColor
      //         );
      //       }
      //     }
      //   }
      // }

      // bugfix reapply last '<br>' (quill editor auto remove last line if it is empty return of line)
      if (_html.substr(-11) == "<p><br></p>") {
        _html += "<p><br></p>";
      }

      // TEST for debug multi space (test KO)
      // replace all space characters by html space notation for keep multi space in editor
      // _html = _html
      //   .replace(/[ \t\r]{2}/g, "&nbsp;&nbsp;")
      //   .replace(/&nbsp;[ \t\r]/g, "&nbsp;&nbsp;")
      //   .replace(/[ \t\r]&nbsp;/g, "&nbsp;&nbsp;");

      //this.editor.clipboard.dangerouslyPasteHTML(_html);
      this.textContent = _html;

      // apply textalign style
      this.editor.root.style.textAlign =
        this.textblock.type === "Footer" ? this.textblock.pagePosition : "left";

      // get text from fabric object if textblock has empty html data
      if (getFabricTextIfEmpty && _html.trim().length === 0) {
        let fabricTextbox =
          this.textblock.target && this.$fabric.canvas
            ? this.$fabric.canvas.getObjectById(this.textblock.target)
            : null;
        if (fabricTextbox && fabricTextbox.text.length > 0) {
          //this.textContent = fabricTextbox.text;
          this.refreshTextColorContrast = true;
          this.editor.setText(fabricTextbox.text);

          // const condensedFlatStyle = fabricTextbox.condensedFlatStyle;
          // if (condensedFlatStyle) {
          //   for (const key of Object.keys(condensedFlatStyle)) {
          //     const [start, end] = key.split("-");
          //     this.editor.formatText(
          //       start,
          //       end - start,
          //       FormatUtils.fabricStyleToCss(
          //         condensedFlatStyle[key],
          //         this.palette.swatches
          //       ),
          //       "silent"
          //     );
          //   }
          // }

          // format fabric style to apply in RTE
          // if (fabricTextbox.styles) {
          //   for (let lineIndex of Object.keys(fabricTextbox.styles)) {
          //   }
          // }

          const fabricStyles = fabricTextbox.getSelectionStyles(
            0,
            fabricTextbox.text.length
          );

          let previousStyle = null,
            fromIndex = null;
          for (
            let charIndex = 0;
            charIndex < fabricStyles.length;
            charIndex++
          ) {
            const currentStyle =
              Object.keys(fabricStyles[charIndex]).length === 0
                ? null
                : JSON.stringify(fabricStyles[charIndex]);

            if (charIndex === 0) {
              previousStyle = currentStyle;
              fromIndex = 0;
            }

            // style change or is last character, force apply style
            if (
              charIndex === fabricStyles.length - 1 ||
              (charIndex > 0 && currentStyle != previousStyle)
            ) {
              if (previousStyle !== null) {
                const extractStyle =
                  FormatUtils.fabricStyleToQuillEditor(previousStyle);

                let toIndex = charIndex - fromIndex;
                if (charIndex === fabricStyles.length - 1) {
                  toIndex++;
                }

                if (extractStyle.textStyleKey) {
                  const themeStyle = this.theme.styles.find(
                    (style) => style.key === extractStyle.textStyleKey
                  );
                  if (themeStyle) {
                    const formatedStyle = Object.assign({}, themeStyle, {
                      style: JSON.parse(themeStyle.style),
                    });

                    this.refreshTextColorContrast = true;
                    this.editor.formatText(fromIndex, toIndex, {
                      fabricStyle: formatedStyle,
                    });
                  }
                }

                if (extractStyle.formatText) {
                  this.refreshTextColorContrast = true;
                  this.editor.formatText(
                    fromIndex,
                    toIndex,
                    extractStyle.formatText
                  );
                }

                if (extractStyle.formatLine) {
                  this.editor.formatLine(
                    fromIndex,
                    toIndex,
                    extractStyle.formatLine
                  );
                }
              }

              previousStyle = currentStyle;
              fromIndex = charIndex;
            }
          }
        }
      }
    },
    onEditorFocus(quill) {
      if (this.textblock && !this.backgroundProcess) {
        const activeObject =
          this.$fabric && this.$fabric.canvas
            ? this.$fabric.canvas.getActiveObject()
            : null;
        if (!activeObject || activeObject.id !== this.textblock.target) {
          this.$fabric.canvas.selectObjectById(this.textblock.target);
        }
      }
    },
    onEditorReady(quill) {
      this.refreshTextContent(true);
      this.editorIsReady = true;
      this.initCanvasListener();

      // add focus to quill editor
      setTimeout(() => {
        this.editor.focus();
        this.editor.setSelection(this.textContent.length);

        if (this.textblock && !this.backgroundProcess) {
          const activeObject =
            this.$fabric && this.$fabric.canvas
              ? this.$fabric.canvas.getActiveObject()
              : null;
          if (activeObject && activeObject.id === this.textblock.target) {
            this.onFabricTextOverflowChanged(
              {
                value: activeObject.textOverflow,
                length: activeObject.textOverflowLength,
              },
              true
            );
          }
        }

        this.$emit("ready");
      }, 100);
    },
    onEditorChange({ html }) {
      const lockSave = this.lockEditorChangeSave;
      this.textContent = html;

      this.lockEditorChangeSave = false;

      // add try catch to debug text change unsaved data
      try {
        // THIS CODE MAKE BUG ON AMM between color 'rose' and 'saumon'
        // REMOVE IF NO NEW BUG WITH PALETTE REPORTED !!!
        // if (
        //   this.usePaletteSwatches &&
        //   this.themePalettes &&
        //   this.themePalettes.length > 0
        // ) {
        //   // override current palette color with default
        //   if (this.palette._id != this.themePalettes[0]._id) {
        //     for (let i = 0; i < this.themePalettes[0].swatches.length; i++) {
        //       const defaultSwatch = this.themePalettes[0].swatches[i],
        //         currentSwatch = this.palette.swatches[i];
        //       const defaultRgbColor = tinycolor(
        //           defaultSwatch.color
        //         ).toRgbString(),
        //         currentRgbColor = tinycolor(currentSwatch.color).toRgbString();
        //       if (defaultRgbColor !== currentRgbColor) {
        //         html = html.replace(
        //           new RegExp(
        //             currentRgbColor
        //               .replace(/\(/g, "\\(")
        //               .replace(/\)/g, "\\)")
        //               .replace(/\./g, "\\."),
        //             "g"
        //           ),
        //           defaultRgbColor
        //         );
        //       }
        //     }
        //   }
        // }

        // remove class 'contrast-text-color' from html to test
        html = html.replace(/contrast-text-color/g, "");

        // apply css mix-blend-mode on current white text
        if (this.refreshTextColorContrast) {
          this.refreshTextColorContrast = false;

          MiscUtils.debounce(
            () => {
              let hasChange = false;
              document
                .querySelectorAll("div.ql-editor *[style]")
                .forEach((element) => {
                  if (
                    element.style.color &&
                    tinycolor(element.style.color).getBrightness() > 230
                  ) {
                    if (!element.classList.contains("contrast-text-color")) {
                      hasChange = true;
                      element.classList.add("contrast-text-color");
                    }
                  } else {
                    if (element.classList.contains("contrast-text-color")) {
                      hasChange = true;
                      element.classList.remove("contrast-text-color");
                    }
                  }
                });
              if (hasChange) {
                if (lockSave) {
                  this.lockEditorChangeSave = true;
                }
              }
            },
            100,
            this,
            "debounceContrast"
          )();
        }

        // if (this.lockEditorChangeSave) {
        //   this.lockEditorChangeSave = false;
        //   return;
        // }

        if (this.textblock.html === html) {
          return;
        }

        // update state in real time
        if (this.textblock.type !== "Footer") {
          this.setText({
            id: this.textblock._id,
            html: html,
            // .replace(
            //   />([^<]*)</g,
            //   (correspondance, p1, decalage, chaine) => {
            //     // replace all space present into html content (not inside the tag) by &nbsp;
            //     console.log("debug here");
            //     return ">" + p1.replace(/\s/g, "&nbsp;") + "<";
            //   }
            // )
            // .replace(/\t/g, "&emsp;")
            // .replace(
            //   />([^\s]*)([\s]+)([^\s]*)</g,
            //   (correspondance, p1, p2, p3, decalage, chaine) => {
            //     // replace all space present into html content (not inside the tag) by &nbsp;
            //     console.log("debug here");
            //     return p1 + "&nbsp;".repeat(p2.length) + p3;
            //   }
            // )
            // .replace(/\t/g, "")
            // .replace(/&nbsp;\s/g, "&nbsp;&nbsp;")
          });
        }

        if (lockSave) {
          return;
        }

        // force save if space or return line
        const lightDebounceSave =
          html.substr(-5) === " </p>" || html.substr(8) === "<br></p>";

        // will send text to fabric with light debounce
        MiscUtils.debounce(
          () => {
            try {
              this.updateText();
            } catch (error) {
              console.error(
                "error on RichTextEditor.vue - onEditorChange - debounceInput",
                error
              );
              this.apiSendError({
                from: "RichTextEditor.vue - onEditorChange - debounceInput",
                error,
              });
            }
          },
          100,
          this,
          "debounceInput"
        )();

        // will save text to server later (from html set in state)
        MiscUtils.debounce(
          () => {
            try {
              if (!this.textblock) {
                // if page has been unloaded before debounce, nothing to do
                return;
              }

              if (this.textblock.type === "Footer") {
                if (this.magazine.isTemplate) {
                  this.saveTemplateMagazine();
                } else {
                  this.saveMagazine();
                }
              } else {
                this.sendAsyncSaveTextblock();
              }
            } catch (error) {
              console.error(
                "error on RichTextEditor.vue - onEditorChange - debounceSave",
                error
              );
              this.apiSendError({
                from: "RichTextEditor.vue - onEditorChange - debounceSave",
                error,
              });
            }
          },
          lightDebounceSave ? 100 : 1000,
          this,
          "debounceSave"
        )();
      } catch (error) {
        console.error("error on RichTextEditor.vue - onEditorChange", error);
        this.apiSendError({
          from: "RichTextEditor.vue - onEditorChange",
          error,
        });
      }
    },
    async onClickUndoText() {
      if (this.lockSendSaveTextblock) {
        return;
      }

      this.lockSendSaveTextblock = true;
      await this.undoTextblock({
        id: this.textblock._id,
      });

      this.onTextblockChanged();

      this.lockSendSaveTextblock = false;

      if (this.resendSaveTextBlock) {
        this.resendSaveTextBlock = false;
        this.$nextTick(() => {
          this.sendAsyncSaveTextblock();
        });
      }
    },
    async onClickRedoText() {
      if (this.lockSendSaveTextblock) {
        return;
      }

      this.lockSendSaveTextblock = true;
      await this.redoTextblock({
        id: this.textblock._id,
      });
      this.onTextblockChanged();

      this.lockSendSaveTextblock = false;

      if (this.resendSaveTextBlock) {
        this.resendSaveTextBlock = false;
        this.$nextTick(() => {
          this.sendAsyncSaveTextblock();
        });
      }
    },
    async sendAsyncSaveTextblock() {
      if (this.lockSendSaveTextblock) {
        this.resendSaveTextBlock = true;
        return;
      }

      this.lockSendSaveTextblock = true;

      if (this.textblock.target && !this.backgroundProcess) {
        this.$bus.$emit("rteTextHasChanged", this.textblock.target);
      }
      await this.saveTextblock({
        id: this.textblock._id,
        keys: ["html"],
      });

      this.lockSendSaveTextblock = false;

      if (this.resendSaveTextBlock) {
        this.resendSaveTextBlock = false;
        this.$nextTick(() => {
          this.sendAsyncSaveTextblock();
        });
      }
    },
    generateLoremIpsum(units) {
      let text = loremIpsum({ count: 1, units: units });
      if (units === "paragraphs") {
        text += "\n";
      } else {
        text += " ";
      }
      const textLength = this.editor.getLength();
      this.editor.insertText(textLength - 1, text);
    },
    clearCurrentText() {
      const textLength = this.editor.getLength();
      this.editor.deleteText(0, textLength);
    },
    generateVariable(name) {
      const text = "{" + name + "}";

      const range = this.editor.getSelection();
      this.editor.insertText(range ? range.index + range.length : 0, text);
    },
  },
};
</script>


<style scoped lang="scss">
@import "~assets/css/mz-variables.scss";

.rte-element {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-top: 1px solid $public-color-grey-2;

  & .rte-actionbar {
    padding: $margin-1 $margin-2;
    border-bottom: 1px solid $public-color-grey-2;
    flex-wrap: wrap;
  }

  & .rte-text-generator {
    padding: $margin-0 $margin-2;
    border-bottom: 1px solid $public-color-grey-2;
  }

  & .quill-editor-content {
    overflow: auto;
    flex: 1;
    display: flex;
  }

  & .quill-editor {
    padding: $margin-1 $margin-2;
    flex: 1;
  }
}

/deep/.ql-container .ql-editor {
  //white-space: normal !important;

  & a {
    color: var(--rte-link-color);
    text-decoration: var(--rte-link-text-decoration);
    font-weight: var(--rte-link-font-weight);
    font-style: var(--rte-link-font-style);
  }

  & ul > li::before {
    content: var(--rte-list-character);
    color: var(--rte-list-color);
  }

  & .contrast-text-color {
    mix-blend-mode: difference;
    opacity: 0.25;
  }

  & sup,
  sub {
    font-size: 70% !important;
  }

  & .overflowText {
    background: #fdd5d5 !important;
  }
}
</style>