<template>
  <div>
    <el-alert
      v-show="displayAlert"
      :type="typeAlert"
      :closable="alertIsClosable"
      center
      effect="dark"
      class="maintenance-alert"
    >
      <span slot="title" v-html="titleAlert" />
    </el-alert>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "MaintenanceAlert",

  data() {
    return {};
  },
  computed: {
    ...mapGetters("app", [
      "maintenanceMode",
      "publicMessage",
      "publicMessageClosable",
      "forceMaintenanceAccess",
    ]),

    displayAlert() {
      return this.maintenanceMode || this.publicMessage;
    },

    titleAlert() {
      return this.maintenanceMode
        ? "Application is currently in maintenance"
        : decodeURIComponent(this.publicMessage);
    },

    typeAlert() {
      return this.maintenanceMode ? "error" : "warning";
    },

    alertIsClosable() {
      return this.maintenanceMode ? false : this.publicMessageClosable;
    },
  },
  watch: {
    maintenanceMode(value) {
      if (value && !this.forceMaintenanceAccess) {
        this.$go2Route({ name: "maintenance" });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.maintenance-alert {
  z-index: 10;
}

/deep/.cta-link {
  display: inline-block;
  background: #b97713;
  color: #fff;
  padding: 2px 15px;
  margin-left: 15px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    background: #a86a0c;
    text-decoration: none;
  }
}
</style>