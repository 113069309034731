<template>
  <div :key="magazine._id">
    <el-link v-if="thumbUrlLink" :href="thumbUrlLink" target="_blank">
      <MzPageImage :page="page" :type="type" />
    </el-link>
    <MzPageImage v-else :page="page" :type="type" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import ImageUtils from "~/common/utils/imageUtils";

import MzPageImage from "~/components/page/MzPageImage";

export default {
  name: "MzMagazineThumb",
  components: { MzPageImage },

  props: ["magazine", "type", "linkToThumb"],
  data() {
    return {};
  },
  computed: {
    page() {
      return {
        _id: this.magazine.thumb,
        updatedAt: this.magazine.lastVisualChangeAt,
        double: false,
      };
    },
    thumbUrlLink() {
      return this.linkToThumb
        ? ImageUtils.getPageSrc(this.magazine.thumb)
        : undefined;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
</style>