<template>
  <div>
    <el-row type="flex" align="middle" class="rte-actionbar">
      <MzIconButton
        :disabled="!canClearStyle"
        src="/icons/text_reset_16.svg"
        :noBorder="true"
        type="small"
        @click="clearStyle"
        class="ml-0"
        :tooltip="$t('public.edit_magazine.clear_style_tooltip')"
        tooltipPlacement="top"
      />

      <el-select
        :value="currentStyleName"
        size="small"
        @change="selectedStyleChanged($event)"
        @visible-change="selectVisibleChange"
        @mousedown.native.prevent="lockCurrentSelection(true)"
        @touchstart.native.prevent="lockCurrentSelection(true)"
        :placeholder="$t('public.edit_magazine.select_style')"
        class="mr-0"
        style="min-width: 100px"
        v-show="stylesListGrouped.length > 0"
      >
        <el-option-group
          v-for="group in stylesListGrouped"
          :key="group.label"
          :label="group.label"
        >
          <el-option
            v-for="item in group.styles"
            :key="item.name"
            :label="item.name"
            :value="item.value"
          >
            <span class="htmlTag" v-if="adminMode">{{
              "&lt;" + item.htmlTag + "&gt; "
            }}</span>
            <span class="ml-0 styleName">{{ item.name }}</span>
            <!-- :style="item.style" -->
          </el-option>
        </el-option-group>
      </el-select>

      <el-select
        :value="currentSelectionFontFamily"
        :placeholder="currentSelectionFontFamilyPlaceHolder"
        filterable
        default-first-option
        size="small"
        @change="applyStyleToSelection('fontFamily', $event ? $event : false)"
        @visible-change="selectVisibleChange"
        @mousedown.native.prevent="lockCurrentSelection(true)"
        @touchstart.native.prevent="lockCurrentSelection(true)"
        style="min-width: 100px"
        class="mr-0"
      >
        <el-option-group v-if="currentSelectionFontFamily">
          <el-option
            :label="$t('public.edit_magazine.resetFontFamily')"
            :value="false"
          />
        </el-option-group>
        <el-option-group>
          <el-option
            v-for="font in fontFamilyList"
            :key="font.value"
            :label="font.label"
            :value="font.value"
            :style="font.style"
          />
        </el-option-group>
      </el-select>

      <el-select
        :value="currentSelectionFontSize"
        :placeholder="currentSelectionFontSizePlaceHolder"
        filterable
        allow-create
        default-first-option
        size="small"
        @change="
          applyStyleToSelection(
            'fontSize',
            $event && parseInt($event) > 0 ? parseInt($event) + 'px' : false
          )
        "
        @visible-change="selectVisibleChange"
        @mousedown.native.prevent="lockCurrentSelection(true)"
        @touchstart.native.prevent="lockCurrentSelection(true)"
        style="width: 70px"
        class="mr-0"
      >
        <el-option-group v-if="currentSelectionFontSize">
          <el-option
            :label="$t('public.edit_magazine.resetFontSize')"
            :value="false"
          />
        </el-option-group>
        <el-option-group>
          <el-option
            v-for="size in fontSizeList"
            :key="size.value"
            :label="size.label"
            :value="size.value"
          />
        </el-option-group>
      </el-select>

      <el-popover
        v-if="colorsList.length > 0"
        placement="bottom"
        trigger="click"
        class="ml-0 mr-0"
        v-model="displayColorPopover"
        @show="lockCurrentSelection(true)"
        @hide="lockCurrentSelection(false)"
      >
        <el-row>
          <Compact
            :value="{}"
            :palette="colorsList"
            @input="selectedColorChanged($event)"
          ></Compact>
        </el-row>
        <el-tooltip
          slot="reference"
          :content="$t('public.edit_magazine.change_color_tooltip')"
          placement="top"
          :open-delay="500"
          :enterable="false"
        >
          <el-button
            circle
            class="icon-select-color"
            :style="{
              backgroundColor: currentSelectionTextColor,
            }"
          ></el-button>
        </el-tooltip>
      </el-popover>
    </el-row>

    <el-row type="flex" align="middle" class="rte-actionbar mt-0">
      <el-popover
        placement="bottom"
        trigger="click"
        class="ml-0"
        v-model="displayAlignPopover"
        @show="lockCurrentSelection(true)"
        @hide="lockCurrentSelection(false)"
      >
        <el-row>
          <MzIconButton
            src="/icons/text_align_left.svg"
            :noBorder="true"
            type="small"
            :selected="selection.style.textAlign == 'left'"
            @click="applyStyleToCurrentLine('textAlign', 'left')"
            :tooltip="$t('public.edit_magazine.left_align_tooltip')"
          />
          <MzIconButton
            src="/icons/text_align_center.svg"
            :noBorder="true"
            :selected="selection.style.textAlign == 'center'"
            @click="applyStyleToCurrentLine('textAlign', 'center')"
            :tooltip="$t('public.edit_magazine.center_align_tooltip')"
          />
          <MzIconButton
            src="/icons/text_align_right.svg"
            :noBorder="true"
            :selected="selection.style.textAlign == 'right'"
            @click="applyStyleToCurrentLine('textAlign', 'right')"
            :tooltip="$t('public.edit_magazine.right_align_tooltip')"
          />
          <MzIconButton
            src="/icons/text_align_justify.svg"
            :noBorder="true"
            :selected="selection.style.textAlign == 'justify'"
            @click="applyStyleToCurrentLine('textAlign', 'justify')"
            :tooltip="$t('public.edit_magazine.justify_tooltip')"
          />
        </el-row>

        <MzIconButton
          slot="reference"
          :src="
            '/icons/text_align_' +
            (selection.style.textAlign ? selection.style.textAlign : 'left') +
            '.svg'
          "
          :noBorder="true"
          :disableStopEvent="true"
        />
      </el-popover>

      <MzIconButton
        src="/icons/text_bold_16.svg"
        class="ml-0"
        :noBorder="true"
        :selected="selection.style.bold"
        @click="applyStyleToSelection('bold', !selection.style.bold)"
        :tooltip="$t('public.edit_magazine.bold_tooltip')"
        tooltipPlacement="top"
      />
      <MzIconButton
        src="/icons/text_italic_16.svg"
        class="ml-0"
        :noBorder="true"
        :selected="selection.style.italic"
        @click="applyStyleToSelection('italic', !selection.style.italic)"
        :tooltip="$t('public.edit_magazine.italic_tooltip')"
        tooltipPlacement="top"
      />
      <MzIconButton
        src="/icons/text_strikethrough_16.svg"
        class="ml-0"
        :noBorder="true"
        :selected="selection.style.strike"
        @click="applyStyleToSelection('strike', !selection.style.strike)"
        :tooltip="$t('public.edit_magazine.strikethrough_tooltip')"
        tooltipPlacement="top"
      />
      <MzIconButton
        src="/icons/text_underline_16.svg"
        class="ml-0"
        :noBorder="true"
        :selected="selection.style.underline"
        @click="applyStyleToSelection('underline', !selection.style.underline)"
        :tooltip="$t('public.edit_magazine.underline_tooltip')"
        tooltipPlacement="top"
      />

      <MzIconButton
        src="/icons/text_link_add_16.svg"
        class="ml-0"
        :noBorder="true"
        @click="showAddTextLink()"
        :tooltip="$t('public.edit_magazine.link_tooltip')"
        tooltipPlacement="top"
      />

      <el-popover
        placement="bottom"
        trigger="click"
        class="ml-0"
        v-model="displayLineHeightPopover"
        @show="lockCurrentSelection(true)"
        @hide="lockCurrentSelection(false)"
      >
        <el-row>
          <el-tag
            type="info"
            @click="applyStyleToCurrentLine('lineHeight', false)"
            class="tag-lineheight"
            :effect="!selection.style.lineHeight ? 'dark' : 'plain'"
          >
            {{ $t("public.edit_magazine.line_height_default_tooltip") }}
          </el-tag>
          <el-tag
            type="info"
            @click="applyStyleToCurrentLine('lineHeight', '1')"
            class="tag-lineheight"
            :effect="selection.style.lineHeight == '1' ? 'dark' : 'plain'"
          >
            {{ $t("public.edit_magazine.line_height_single_tooltip") }}
          </el-tag>
          <el-tag
            type="info"
            @click="applyStyleToCurrentLine('lineHeight', '1.25')"
            class="tag-lineheight"
            :effect="selection.style.lineHeight == '1.25' ? 'dark' : 'plain'"
          >
            x1.25
          </el-tag>
          <el-tag
            type="info"
            @click="applyStyleToCurrentLine('lineHeight', '1.5')"
            class="tag-lineheight"
            :effect="selection.style.lineHeight == '1.5' ? 'dark' : 'plain'"
          >
            x1.5
          </el-tag>
          <el-tag
            type="info"
            @click="applyStyleToCurrentLine('lineHeight', '2')"
            class="tag-lineheight"
            :effect="selection.style.lineHeight == '2' ? 'dark' : 'plain'"
          >
            x2
          </el-tag>
        </el-row>

        <MzIconButton
          slot="reference"
          src="/icons/text_lineheight.svg"
          :noBorder="true"
          :disableStopEvent="true"
          :tooltip="$t('public.edit_magazine.line_height_tooltip')"
          tooltipPlacement="top"
        />
      </el-popover>

      <MzIconButton
        src="/icons/text_superscript.svg"
        :noBorder="true"
        class="ml-0"
        @click="
          applyStyleToSelection(
            'script',
            selection.style.script === 'super' ? false : 'super'
          )
        "
        :selected="selection.style.script === 'super'"
        :tooltip="$t('public.edit_magazine.superscript_tooltip')"
        tooltipPlacement="top"
      />

      <MzIconButton
        src="/icons/text_subscript.svg"
        :noBorder="true"
        class="ml-0"
        @click="
          applyStyleToSelection(
            'script',
            selection.style.script === 'sub' ? false : 'sub'
          )
        "
        :selected="selection.style.script === 'sub'"
        :tooltip="$t('public.edit_magazine.subscript_tooltip')"
        tooltipPlacement="top"
      />

      <MzIconButton
        src="/icons/text_numberslist_16.svg"
        class="ml-0"
        :noBorder="true"
        :selected="selection.style.list === 'ordered'"
        @click="switchList('ordered')"
        :tooltip="$t('public.edit_magazine.numbered_list_tooltip')"
        tooltipPlacement="top"
      />
      <MzIconButton
        src="/icons/text_list_16.svg"
        class="ml-0"
        :noBorder="true"
        :selected="selection.style.list === 'bullet'"
        @click="switchList('bullet')"
        :tooltip="$t('public.edit_magazine.bulleted_list_tooltip')"
        tooltipPlacement="top"
      />

      <el-popover
        placement="bottom"
        trigger="click"
        class="ml-0"
        v-model="displayInsertCharsPopover"
        popper-class="rte-action-popover"
      >
        <el-row class="rte-action-title">
          {{ $t("public.edit_magazine.insert_characters.space_title") }}
        </el-row>
        <el-row class="rte-action" @click.native="insertChars(0x00a0)">
          {{ $t("public.edit_magazine.insert_characters.no-break-space") }}
        </el-row>

        <MzIconButton
          slot="reference"
          src="/icons/paragraph.svg"
          :noBorder="true"
          :tooltip="$t('public.edit_magazine.insert_characters_tooltip')"
          tooltipPlacement="top"
          :disableStopEvent="true"
        />
      </el-popover>

      <el-popover
        v-if="colorsList.length > 0"
        placement="bottom"
        trigger="click"
        class="ml-0 mr-0"
        v-model="displayBackgroundColorPopover"
        @show="lockCurrentSelection(true)"
        @hide="lockCurrentSelection(false)"
      >
        <el-row type="flex" justify="left" align="middle">
          <span
            class="clean-background-color"
            @click="selectedBackgroundColorChanged(false)"
          >
            X
          </span>
          <Compact
            :value="{}"
            :palette="colorsList"
            @input="selectedBackgroundColorChanged($event)"
          ></Compact>
        </el-row>
        <el-tooltip
          slot="reference"
          :content="$t('public.edit_magazine.change_backgroundcolor_tooltip')"
          placement="top"
          :open-delay="500"
          :enterable="false"
        >
          <span class="content-background-color">
            <MzIconButton
              src="/icons/text_highlight.svg"
              :noBorder="true"
              :disableStopEvent="true"
            />
            <span
              class="display-background-color"
              :style="{
                backgroundColor: currentSelectionBackgroundColor,
              }"
              >&nbsp;</span
            >
          </span>
        </el-tooltip>
      </el-popover>

      <MzIconButton
        :disabled="updateInProgress || !textblock.canUndo"
        src="/icons/text_undo.svg"
        :noBorder="true"
        type="small"
        @click="onClickUndoText"
        :tooltip="$t('public.edit_magazine.undo_text')"
        tooltipPlacement="top"
        class="ml-0"
      />
      <MzIconButton
        :disabled="updateInProgress || !textblock.canRedo"
        src="/icons/text_redo.svg"
        :noBorder="true"
        type="small"
        @click="onClickRedoText"
        class="ml-0"
        :tooltip="$t('public.edit_magazine.redo_text')"
        tooltipPlacement="top"
      />
    </el-row>

    <el-dialog
      :title="$t('public.edit_magazine.add_text_link')"
      :visible.sync="displayAddLinkModal"
      append-to-body
      @closed="addLinkModalClosed"
    >
      <AddLinkForm ref="addLinkForm" :defaultLink="currentlink" />

      <span slot="footer" class="dialog-footer">
        <el-button
          @click="displayAddLinkModal = false"
          v-t="'public.alert.cancel'"
        ></el-button>
        <el-button
          type="primary"
          @click="confirmUpdateTextLink"
          v-t="'public.alert.validate'"
        ></el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import FormatUtils from "~/utils/format";
import Validators from "~/utils/validators";
import MiscUtils from "~/common/utils/misc";
import { Compact } from "vue-color";
import tinycolor from "tinycolor2";

import MzIconButton from "~/components/MzIconButton";
import AddLinkForm from "./AddLinkForm.vue";

export default {
  name: "RichTextEditorActions",
  components: { MzIconButton, Compact, AddLinkForm },

  props: ["editor", "textblock", "isAdminEditor"],
  data() {
    return {
      updateInProgress: false,
      currentStyleName: null,
      displayAddLinkModal: false,
      currentlink: null,
      displayColorPopover: false,
      displayBackgroundColorPopover: false,
      displayAlignPopover: false,
      displayInsertCharsPopover: false,
      displayAIFeaturesPopover: false,
      displayLineHeightPopover: false,
      defaultTextboxStyleName: null,
      defaultTextboxStyleKey: null,
      defaultTextBoxColor: null,
      defaultTextBoxFontSize: null,
      defaultTextBoxFontFamily: null,
      hasMultiFontSize: false,
      hasMultiFontFamily: false,
      hasMultiFabricStyle: false,

      lockSelection: false,
      selection: {
        index: null,
        range: null,
        style: {},
      },
    };
  },
  computed: {
    ...mapState("palette", ["palette", "defaultPalettePublicColors"]),
    ...mapState("theme", ["theme"]),
    ...mapState("magazine", ["magazine"]),
    ...mapState("page", ["page"]),
    ...mapGetters("app", ["adminMode"]),
    ...mapGetters("magazine", ["pageNameMap", "checkMagazinePerm"]),
    ...mapGetters("theme", [
      "findTextblockTypeData",
      "getDefaultFabricTextStyle",
    ]),
    ...mapGetters("palette", ["getColorBySwatchName"]),
    ...mapGetters("article", ["getTextblock"]),

    hasTextSelection() {
      return this.selection && this.selection.range !== null;
    },
    hasCursorIndex() {
      return this.selection && this.selection.index !== null;
    },

    canClearStyle() {
      return this.editor.getLength() > 0;
      // if (this.hasTextSelection) {
      //   return Object.keys(this.selection.style).length > 0;
      // } else {
      //   return true;
      // }
    },

    currentSelectionFabricStyle() {
      if (
        this.selection.style.fabricStyle &&
        this.selection.style.fabricStyle.key
      ) {
        const currentStyle = this.theme.styles.find(
          (style) => style.key === this.selection.style.fabricStyle.key
        );
        if (currentStyle && currentStyle.style) {
          const jsonStyle = JSON.parse(currentStyle.style);
          return jsonStyle;
        }
      }
      return null;
    },

    currentSelectionTextColor() {
      if (this.selection && this.selection.style) {
        if (this.selection.style.color) {
          return this.selection.style.color;
        } else if (this.currentSelectionFabricStyle) {
          if (
            this.currentSelectionFabricStyle.fill &&
            this.getColorBySwatchName[this.currentSelectionFabricStyle.fill]
          ) {
            return this.getColorBySwatchName[
              this.currentSelectionFabricStyle.fill
            ];
          }
        }
      }
      return this.defaultTextBoxColor;
    },
    currentSelectionBackgroundColor() {
      if (this.selection && this.selection.style) {
        if (this.selection.style.background) {
          return this.selection.style.background;
        } else if (this.currentSelectionFabricStyle) {
          if (
            this.currentSelectionFabricStyle.textBackgroundColor &&
            this.getColorBySwatchName[
              this.currentSelectionFabricStyle.textBackgroundColor
            ]
          ) {
            return this.getColorBySwatchName[
              this.currentSelectionFabricStyle.textBackgroundColor
            ];
          }
        }
      }
      return null;
    },
    currentSelectionFontSizePlaceHolder() {
      if (this.hasMultiFontSize) {
        return " ";
      }

      if (this.selection && this.selection.style) {
        if (this.currentSelectionFabricStyle) {
          if (this.currentSelectionFabricStyle.fontSize) {
            return (
              "" +
              Math.round(
                MiscUtils.convertFromPixel(
                  this.currentSelectionFabricStyle.fontSize,
                  "pt"
                )
              )
            );
          }
        }
      }
      return (
        "" +
        Math.round(
          MiscUtils.convertFromPixel(this.defaultTextBoxFontSize, "pt")
        )
      );
    },
    currentSelectionFontSize() {
      if (this.selection && this.selection.style) {
        if (
          this.selection.style.fontSize &&
          this.selection.style.fontSize.indexOf("rem") === -1
        ) {
          return Math.round(
            MiscUtils.convertFromPixel(
              parseFloat(this.selection.style.fontSize),
              "pt"
            )
          );
        }
      }
      return null;
    },
    currentSelectionFontFamilyPlaceHolder() {
      if (this.hasMultiFontFamily) {
        return " ";
      }

      if (this.selection && this.selection.style) {
        if (this.currentSelectionFabricStyle) {
          if (this.currentSelectionFabricStyle.fontFamily) {
            return this.currentSelectionFabricStyle.fontFamily;
          }
        }
      }
      return this.defaultTextBoxFontFamily;
    },
    currentSelectionFontFamily() {
      if (this.selection && this.selection.style) {
        if (this.selection.style.fontFamily) {
          return this.selection.style.fontFamily.replace(/"/g, ""); // bugfix remove `"` present in font family if name contains space
        }
      }
      return null;
    },
    textblockTypeData() {
      return this.findTextblockTypeData(this.textblock.type);
    },

    stylesListGrouped() {
      // format array to be used in list
      let formated = this.theme.styles.reduce(
        (filtered, s) => {
          if (
            (s.element === "header" || s.element === "paragraph") &&
            !s.hideInRTE &&
            (this.isAdminEditor ||
              !this.textblockTypeData ||
              this.textblockTypeData.availableStyles === null ||
              this.textblockTypeData.availableStyles.indexOf(s._id) >= 0)
          ) {
            const fabricStyle = {
              ...this.getDefaultFabricTextStyle,
              ...JSON.parse(s.style),
            };
            let cssStyle;

            // old process (before use style key)
            if (!s.key) {
              // remove properties with color from fabric style
              fabricStyle.stroke = undefined;
              fabricStyle.fill = undefined;
              fabricStyle.textBackgroundColor = undefined;

              cssStyle = FormatUtils.fabricStyleToCss(s.style, null); // get style without palette color //this.palette.swatches)
              cssStyle.color = "black";
            } else {
              cssStyle = FormatUtils.fabricStyleToCss(
                s.style,
                this.palette.swatches
              ); // get style
            }

            filtered[s.element].push({
              value: Object.assign({}, s, {
                style: JSON.stringify(fabricStyle),
              }),
              name: s.name,
              style: cssStyle,
              htmlTag: (s.htmlTag || "span").toLowerCase(),
            });
          }
          return filtered;
        },
        { header: [], paragraph: [] }
      );

      const result = [];
      if (formated.header && formated.header.length > 0) {
        result.push({
          label: this.$t("public.edit_magazine.header_style"),
          styles: formated.header,
        });
      }
      if (formated.paragraph && formated.paragraph.length > 0) {
        result.push({
          label: this.$t("public.edit_magazine.paragraph_style"),
          styles: formated.paragraph,
        });
      }
      return result;
    },

    stylesList() {
      const _grouped = this.stylesListGrouped;
      return _grouped.reduce((list, value) => {
        list = list.concat(value.styles);
        return list;
      }, []);
    },

    fontSizeList() {
      const list = [];
      for (let i = 6; i <= 60; i = i + 2) {
        list.push({
          value: MiscUtils.parseToPixel(i + "pt") + "px",
          label: i,
        });
      }
      return list;
    },

    fontFamilyList() {
      return this.$fontManager.defaultFonts
        .map((font) => {
          return {
            value: font.name,
            label: font.name,
            style: "font-family:" + font.name + ";",
          };
        })
        .sort((f1, f2) => f1.label.localeCompare(f2.label));
    },

    colorsList() {
      // get available color list
      const colors = this.palette.swatches.reduce(
        (arr, swatch) => {
          if (
            this.isAdminEditor ||
            !this.textblockTypeData ||
            this.textblockTypeData.availableColors === null ||
            this.textblockTypeData.availableColors.indexOf(swatch.name) >= 0
          ) {
            const color = tinycolor(swatch.color).toHex8String();
            if (arr.indexOf(color) === -1) {
              arr.push(color);
            }
          }
          return arr;
        },
        [...this.defaultPalettePublicColors]
      );
      return colors;
    },

    pagesToLink() {
      return this.magazine.pageOrder.reduce((list, page) => {
        if (page._id !== this.page._id) {
          if (page.double) {
            list.push({
              page,
              label: this.pageNameMap[page._id + "_left"],
              value: "#page_" + page._id + "_left",
            });
            list.push({
              page,
              label: this.pageNameMap[page._id + "_right"],
              value: "#page_" + page._id + "_right",
            });
          } else {
            list.push({
              page,
              label: this.pageNameMap[page._id],
              value: "#page_" + page._id,
            });
          }
        }
        return list;
      }, []);
    },
  },
  mounted() {
    let fabricTextbox =
      this.textblock.target && this.$fabric.canvas
        ? this.$fabric.canvas.getObjectById(this.textblock.target)
        : null;
    if (fabricTextbox) {
      // get default text style and color
      const boxStyle = fabricTextbox.getTextBoxStyle(false);
      if (boxStyle) {
        this.defaultTextBoxColor = boxStyle.fill;
        this.defaultTextBoxFontSize = boxStyle.fontSize;
        this.defaultTextBoxFontFamily = boxStyle.fontFamily;

        if (fabricTextbox.textStyleKey) {
          let res = this.stylesList.find(
            (s) => s.value.key == fabricTextbox.textStyleKey
          );
          this.defaultTextboxStyleKey = fabricTextbox.textStyleKey;
          this.defaultTextboxStyleName = res ? res.name : null;
        } else {
          // old process (before use style key)
          // remove properties with color from fabric textbox style
          boxStyle.stroke = undefined;
          boxStyle.fill = undefined;
          boxStyle.textBackgroundColor = undefined;
          const jsonBoxStyle = JSON.stringify(boxStyle);

          let res = this.stylesList.find((s) => s.value.style == jsonBoxStyle);
          this.defaultTextboxStyleName = res ? res.name : null;
        }
      }
    }

    this.setMatchingStyle();
    this.editor.on("selection-change", this.textBoxSelectedHandler);
  },
  beforeDestroy() {
    this.editor.off("selection-change", this.textBoxSelectedHandler);
  },
  methods: {
    canUpdateText() {
      if (!this.hasTextSelection) {
        this.$message({
          type: "error",
          message: this.$t("public.edit_magazine.edit_text_selection_error"),
        });
        return false;
      }

      return true;
    },
    insertChars(charCode) {
      if (this.hasCursorIndex) {
        this.editor.insertText(
          this.selection.index,
          String.fromCharCode(charCode)
        );
      }
      this.displayInsertCharsPopover = false;
    },
    switchList(type) {
      if (this.hasCursorIndex) {
        if (this.selection.style.list && this.selection.style.list === type) {
          this.applyStyleToCurrentLine("list", false);
        } else {
          this.applyStyleToCurrentLine("list", type);
        }
      }
    },
    selectedColorChanged(selectedColor) {
      if (selectedColor) {
        this.applyStyleToSelection(
          "color",
          tinycolor(selectedColor.rgba).toHex8String()
        );
      } else {
        this.applyStyleToSelection("color", false);
      }
    },
    selectedBackgroundColorChanged(selectedColor) {
      if (selectedColor) {
        this.applyStyleToSelection(
          "background",
          tinycolor(selectedColor.rgba).toHex8String()
        );
      } else {
        this.applyStyleToSelection("background", false);
      }
    },
    selectedStyleChanged(selectedStyle) {
      // apply style and color
      if (this.canUpdateText() && selectedStyle !== null) {
        this.currentStyleName = selectedStyle.name;

        this.applyStyleToSelection("fabricStyle", selectedStyle);
        this.applyStyleToSelection("color", false);
        this.applyStyleToSelection("fontSize", false);
        this.applyStyleToSelection("fontFamily", false);
      }
    },
    textBoxSelectedHandler(range, oldRange) {
      // waiting if user has click on style select list
      if (this.lockSelection || range == this.selection.range) {
        return;
      }

      if (range && range.length > 0) {
        this.selection.index = range.index;
        this.selection.range = range;
        this.selection.style = range
          ? this.editor.getFormat(range.index, range.length)
          : {};
      } else {
        this.selection.index = range ? range.index : null;
        this.selection.range = null;
        this.selection.style = range
          ? this.editor.getFormat(range.index, range.length)
          : {};
      }
      this.setMatchingStyle();
    },
    setMatchingStyle() {
      // console.log(
      //   "setMatchingStyle",
      //   JSON.parse(JSON.stringify(this.selection.style))
      // );

      // detect multi style in selection range
      this.hasMultiFontSize = false;
      this.hasMultiFontFamily = false;
      this.hasMultiFabricStyle = false;

      if (this.selection.range && this.selection.range.length > 1) {
        let firstStyleKey = null,
          firstFontFamily = null,
          firstFontSize = null;

        for (let i = 0; i < this.selection.range.length; i++) {
          let charStyle = this.editor.getFormat(
              this.selection.range.index + i,
              1
            ),
            completeCharStyle = null;

          if (charStyle.fabricStyle) {
            let fabricStyle = null;
            try {
              fabricStyle = JSON.parse(charStyle.fabricStyle.style);
            } catch (e) {}

            completeCharStyle = {
              ...this.getDefaultFabricTextStyle,
              ...fabricStyle,
              ...charStyle,
            };
          } else {
            completeCharStyle = {
              ...this.getDefaultFabricTextStyle,
              ...charStyle,
            };
          }

          if (i === 0) {
            firstStyleKey = completeCharStyle.fabricStyle
              ? completeCharStyle.fabricStyle.key
              : null;
            firstFontFamily = completeCharStyle.fontFamily;
            firstFontSize = completeCharStyle.fontSize;
          } else {
            let nextStyleKey = completeCharStyle.fabricStyle
                ? completeCharStyle.fabricStyle.key
                : null,
              nextFontFamily = completeCharStyle.fontFamily,
              nextFontSize = completeCharStyle.fontSize;

            if (nextStyleKey !== firstStyleKey) {
              this.hasMultiFabricStyle = true;
            }
            if (nextFontFamily !== firstFontFamily) {
              this.hasMultiFontFamily = true;
            }
            if (nextFontSize !== firstFontSize) {
              this.hasMultiFontSize = true;
            }
          }

          if (
            this.hasMultiFontFamily &&
            this.hasMultiFontSize &&
            this.hasMultiFabricStyle
          ) {
            break;
          }
        }
      }

      // get current style name for selection
      if (this.hasMultiFabricStyle) {
        this.currentStyleName = " ";
      } else if (!this.selection.style || !this.selection.style.fabricStyle) {
        this.currentStyleName = this.defaultTextboxStyleName;
      } else {
        const fabricStyle =
          this.selection.style.fabricStyle instanceof Array
            ? this.selection.style.fabricStyle[0]
            : this.selection.style.fabricStyle;
        if (fabricStyle.key) {
          // find style by key
          let res = this.stylesList.find((s) => s.value.key == fabricStyle.key);
          if (res) {
            this.currentStyleName = res.name;
            return;
          }
        }

        // old style process, find matching style if not found by bey
        let jsonObjStyle = fabricStyle.style;

        const objStyle = {
          ...this.getDefaultFabricTextStyle,
          ...JSON.parse(jsonObjStyle),
        };
        objStyle.fill = undefined;
        objStyle.stroke = undefined;
        objStyle.textBackgroundColor = undefined;

        jsonObjStyle = JSON.stringify(objStyle);

        let res = this.stylesList.find((s) => s.value.style == jsonObjStyle);
        if (res) {
          this.currentStyleName = res.name;
        } else {
          this.currentStyleName = this.defaultTextboxStyleName;
        }
      }
    },
    showAddTextLink() {
      if (this.canUpdateText()) {
        this.currentlink =
          this.selection.style.link && this.selection.style.link.href
            ? this.selection.style.link.href
            : null;

        this.lockCurrentSelection(true);
        this.displayAddLinkModal = true;
      }
    },
    addLinkModalClosed() {
      this.currentlink = null;
      this.lockCurrentSelection(false);
    },
    confirmUpdateTextLink() {
      if (this.canUpdateText()) {
        if (!this.$refs.addLinkForm.isValid) {
          this.$message({
            type: "error",
            message: this.$t("public.edit_magazine.invalid_link"),
          });
          return;
        }

        const link = this.$refs.addLinkForm.computedLink;
        if (!link) {
          this.applyStyleToSelection("link", false);
        } else {
          this.applyStyleToSelection("link", link);
        }
        this.displayAddLinkModal = false;
        this.currentlink = null;
      }
    },
    onClickUndoText() {
      if (this.updateInProgress) {
        return;
      }

      this.updateInProgress = true;
      this.$emit("undo");

      setTimeout(() => {
        // lock multi click
        this.updateInProgress = false;
      }, 500);
    },
    onClickRedoText() {
      if (this.updateInProgress) {
        return;
      }

      this.updateInProgress = true;
      this.$emit("redo");

      setTimeout(() => {
        // lock multi click
        this.updateInProgress = false;
      }, 500);
    },
    clearStyle() {
      if (this.selection.range) {
        this.editor.removeFormat(
          this.selection.range.index,
          this.selection.range.length
        );
      } else {
        this.editor.removeFormat(0, this.editor.getLength() - 1);
      }
      this.selection.style = {};

      this.setMatchingStyle();
    },
    applyStyleToCurrentLine(key, value) {
      if (this.hasCursorIndex) {
        this.editor.formatLine(
          this.selection.index,
          this.selection.range &&
            this.selection.range.length &&
            this.selection.range.length > 0
            ? this.selection.range.length
            : 1,
          key,
          value
        );

        this.selection.style = this.editor.getFormat();
        this.setMatchingStyle();

        this.displayColorPopover = false;
        this.displayBackgroundColorPopover = false;
        this.displayAlignPopover = false;
        this.displayInsertCharsPopover = false;
        this.displayLineHeightPopover = false;
      }
    },
    applyStyleToSelection(key, value) {
      if (this.canUpdateText()) {
        if (key == "color") {
          this.$emit("colorChanged");
        }

        this.editor.formatText(
          this.selection.range.index,
          this.selection.range.length,
          key,
          value
        );

        this.selection.style = this.editor.getFormat();
        this.setMatchingStyle();

        this.displayColorPopover = false;
        this.displayBackgroundColorPopover = false;
        this.displayAlignPopover = false;
        this.displayInsertCharsPopover = false;
        this.displayLineHeightPopover = false;
      }
    },
    selectVisibleChange(visible) {
      if (!visible) {
        this.lockCurrentSelection(false);
      }
    },
    lockCurrentSelection(value) {
      if (
        this.displayAddLinkModal ||
        this.displayColorPopover ||
        this.displayBackgroundColorPopover ||
        this.displayAlignPopover ||
        this.displayLineHeightPopover
      ) {
        // force lock if modal or popover open
        value = true;
      }

      this.lockSelection = value;
      if (value === false && this.hasTextSelection) {
        this.editor.setSelection(
          this.selection.range.index,
          this.selection.range.length
        );
      }
    },
  },
};
</script>


<style scoped lang="scss">
@import "~assets/css/mz-variables.scss";
.select-page-list {
  & .el-select-dropdown__item {
    height: 100px;
    display: flex;
    align-items: center;
  }
}

.icon-select-color {
  padding: 8px;
  margin-bottom: 7px;
  background-color: $public-color-grey-bg;
  border: 1px solid $public-color-grey-2;
  border-radius: 50%;
}

.content-background-color {
  position: relative;

  & .display-background-color {
    position: absolute;
    bottom: -2px;
    left: 2px;
    width: 20px;
    height: 4px;
    border-radius: 5px;
  }
}

.clean-background-color {
  width: 30px;
  height: 30px;
  box-shadow: inset 1px 1px 2px rgb(95, 95, 95);
  margin: 0.35rem;
  text-align: center;
  font-size: 22px;
  color: #ec6868;
  cursor: pointer;
}

.htmlTag {
  color: $public-color-grey-1;
  font-size: 0.75em;
}

.styleName {
  color: $public-color-grey-1;
}
</style>

<style lang="scss">
@import "~assets/css/mz-variables.scss";

.vc-compact {
  width: auto !important;
  box-shadow: none !important;
  padding: 4px;
  & .vc-compact-color-item {
    width: 30px;
    height: 30px;
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.33);
    margin: 0.35rem;
  }
  & .vc-compact-dot {
    top: 10px;
    left: 10px;
    bottom: 10px;
    right: 10px;
  }
}

.rte-action-popover {
  padding: 0px;

  & .rte-action-title {
    background-color: $public-color-grey-2;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }
  & .rte-action {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding: 0.25rem;
    cursor: pointer;

    &:hover {
      background-color: #f7f7f7;
    }
  }
}

.tag-lineheight {
  cursor: pointer;
}
</style>