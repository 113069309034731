export default function ({ app }, inject) {
  inject('go2Route', (routeParams) => {
    // const to = app.localePath(routeParams);
    // if (app.router.currentRoute.path === to) {
    //   window.location.reload();
    // } else {
    //   app.router.push(to)
    // }
    if (routeParams.target === '_blank') {
      delete routeParams.target;
      const url = routeParams.url ? routeParams.url :
        process.env.VUE_APP_URL +
        app.localePath(routeParams);
      window.open(url, "_blank");
    } else {
      app.router.push(app.localePath(routeParams));
    }
  })
}