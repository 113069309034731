const s3Bucket = process.env.COMMON_AWS_S3_BUCKET,
  s3Resize = process.env.COMMON_AWS_RESIZER_URL,
  s3Url = process.env.COMMON_AWS_S3_URL;

export default {

  getFabricImageResizedData(object, neededScale, maxImageSize = 1500) {
    let width = object.width * object.scaleX * neededScale,
      height = object.height * object.scaleY * neededScale;

    const imgKey = this.getImageBaseKey(object.src);
    if (!imgKey) {
      return null;
    }

    // check max image size for bugfix lambda serve big image
    if (maxImageSize && maxImageSize > 0) {
      if (width > maxImageSize) {
        const ratio = height / width;
        width = maxImageSize;
        height = maxImageSize * ratio;
      }
      if (height > maxImageSize) {
        const ratio = width / height;
        width = maxImageSize * ratio;
        height = maxImageSize;
      }
    }

    // check max original image size
    if (width > object.width || height > object.height) {
      width = object.width;
      height = object.height;
    }

    return {
      src: this.getImageResizedSrc({ key: imgKey }, width, height),
      width,
      height,
      scaleX: (object.width * object.scaleX) / width,
      scaleY: (object.height * object.scaleY) / height,
    };

  },

  getImageResizedSrc(img, width, height, fit = "cover") {
    // use AWS serverless image handler
    //https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/welcome.html

    // setup request config
    let request = {
      bucket: s3Bucket,
      key: img.key
    }

    if (img.updatedAt) {
      request.updatedAt = img.updatedAt;
    }

    // if svg we use svg2png source to get rasterized version before resize
    if (img.type === 'image/svg+xml') {
      let pathArr = img.key.split('/');
      const filename = pathArr.pop();

      let nameArr = filename.split('.');
      const ext = nameArr.pop();
      const pngFilename = nameArr.join('.') + '.png';
      pathArr.push('svg2png');
      pathArr.push(pngFilename);
      const newPath = pathArr.join('/');
      // set new path
      request.key = newPath;
    }
    // set edits parameters to request obj
    request.edits = {
      "resize": {
        "width": Math.round(width),
        "height": Math.round(height),
        "fit": fit
      }
    }
    // Setup encoded request
    const str = JSON.stringify(request);

    // return full url with base64 encoded request
    return s3Resize + Buffer.from(str).toString('base64');
  },

  getImageBaseKey(src) {
    // return src.indexOf(s3Url) === 0 ? src.substr(s3Url.length) : null;
    const match = src.match(new RegExp('https://(.*).s3.(.*).amazonaws.com/(.*)'));
    return match && match.length >= 4 ? match[3] : null;
  },

  getImageBaseSrc(img) {
    return s3Url + img.key;
  },

  computePageKey(pageId, paletteId) {
    const binID = paletteId ? pageId + '_' + paletteId : pageId;
    return 'pages/' + binID + '-page-preview.jpg';
  },

  getPageSrc(pageId, paletteId) {
    const binID = paletteId ? pageId + '_' + paletteId : pageId;
    return s3Url + this.computePageKey(pageId, paletteId);
  },

  computePdfKey(magazineId, exportedPdf) {
    return 'pdfs/' + magazineId + '/' + exportedPdf.version + '/' + exportedPdf.files.magazine;
  },
  computePdfCoverKey(magazineId, exportedPdf) {
    return 'pdfs/' + magazineId + '/' + exportedPdf.version + '/' + exportedPdf.files.cover;
  },

  getPdfSrc(magazineId, version, fileName) {
    return s3Url + 'pdfs/' + magazineId + '/' + version + '/' + fileName;
  },

  getViewerCodeSrc(magazineId, fileName) {
    return s3Url + 'zips/' + magazineId + '/' + fileName;
  },

  getWorkspaceMagazinesZipSrc(workspaceId, version, fileName) {
    return s3Url + 'zips/' + workspaceId + '/' + version + '/' + fileName;
  },
}
