<template>
  <div>
    <script
      v-if="needInstantiateScript"
      src="https://widget.yourgpt.ai/script.js"
      id="yourgpt-chatbot"
      data-widget="d1aa2cf5-4302-41bc-b073-fcafef8ac6d4"
    ></script>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "ChatBotAi",
  props: ["setCurrentUser"],

  data() {
    return {
      checkInterval: null,
      hasSetUser: false,
      needInstantiateScript: false,
    };
  },
  computed: {
    ...mapGetters("api", ["isLogged"]),
    ...mapState("api", ["user"]),
  },

  created() {
    this.checkInterval = setInterval(this.checkChatbot, 1000);
    this.needInstantiateScript = !window.$yourgptChatbot;
    if (document.getElementById("yourgpt_root")) {
      document.getElementById("yourgpt_root").style.display = "unset";
    }
  },
  beforeDestroy() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
      this.checkInterval = null;
    }

    if (document.getElementById("yourgpt_root")) {
      document.getElementById("yourgpt_root").style.display = "none";
    }
  },
  methods: {
    checkChatbot() {
      if (!window.$yourgptChatbot) {
        return;
      }

      if (this.checkInterval) {
        clearInterval(this.checkInterval);
        this.checkInterval = null;
        console.log("Chatbot is loaded");
      }

      if (this.setCurrentUser && this.isLogged && !this.hasSetUser) {
        this.hasSetUser = true;

        const userData = this.user.email
          ? {
              user_id: this.user._id,
              email: this.user.email,
              name: this.user.name,
            }
          : {
              user_id: this.user._id,
              name: this.user.name,
            };
        console.log(
          "Chatbot set connected user",
          JSON.parse(JSON.stringify(this.user))
        );
        if (this.user.chatbotHmac) {
          window.$yourgptChatbot.set("contact:data", {
            email: this.user.email ? this.user.email : this.user.ssoUserId,
            name: this.user.name,
            ext_user_id: this.user._id,
            user_hash: this.user.chatbotHmac,
          });
        }
        window.$yourgptChatbot.set("visitor:data", userData);
        window.$yourgptChatbot.set("session:data", userData);
      } else if (!this.isLogged && this.hasSetUser) {
        this.hasSetUser = false;
        console.log("Chatbot unset connected user");
        window.$yourgptChatbot.set("contact:data", null);
        window.$yourgptChatbot.set("visitor:data", null);
        window.$yourgptChatbot.set("session:data", null);
      }
    },
  },
  watch: {
    isLogged(newVal) {
      this.$nextTick(() => {
        this.checkChatbot();
      });
    },
  },
};
</script>

<style lang="scss">
.ygpts-widgetBtn img {
  border-radius: 0 !important;
  width: 75% !important;
}
</style>
