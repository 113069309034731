import MiscUtils from "~/common/utils/misc";

export default {
  // fontFamily: '',
  // fill
  // fontWeight: 'normal',
  // fontSize: 10,
  // fontStyle: 'normal',
  // underline: false,
  // overline: false,
  // linethrough: false,
  // textBackgroundColor: '',
  // lineHeight: 1,
  // textAlign: '',

  // convertCssValues
  convertCsstoFabric(cssStyle, includeLineStyle) {
    let output = {};
    let ref = null;

    // key = css prop, name: fabricjs prop, value: function to map quill to fabricjs values
    let map = new Map([
      ['bold', {
        name: "fontWeight",
        value: val => new Map([
          [true, 'bold'],
          [false, 'normal']
        ]).get(val)
      }],
      ['italic', {
        name: "fontStyle",
        value: val => new Map([
          [true, 'italic'],
          [false, 'normal']
        ]).get(val)
      }],
      ['underline', {
        name: "underline",
        value: val => val
      }],
      ['strike', {
        name: "linethrough",
        value: val => val
      }],
      ['background', {
        name: "textBackgroundColor",
        value: val => val
      }],
      ['color', {
        name: "fill",
        value: val => val
      }],
      ['link', {
        name: "link",
        value: val => typeof val !== 'string' ? val.href : val
      }],
      ['textAlign', {
        name: "textAlign",
        value: val => (val === 'justify' ? 'justify-left' : val)
      }],
      ['lineHeight', {
        name: "lineHeight",
        value: val => val
      }],
      ['fontSize', {
        name: "fontSize",
        value: val => {
          if (val.indexOf('rem') >= 0) {
            return null;
          }
          return MiscUtils.parseToPixel(val);
        }
      }],
      ['fontFamily', {
        name: "fontFamily",
        value: val => val.replace(/"/g, "") // bugfix remove `"` present in font family if name contains space
      }],

      // specific case for list attribute, add style only on first char
      ['list', {
        name: "list",
        value: val => includeLineStyle ? val : undefined
      }],
      ['indent', {
        name: "indent",
        value: val => includeLineStyle ? val : undefined
      }],
      ['script', {
        name: "script",
        value: val => val
      }],
    ]);

    let defaultFabricStyle = {};
    if (cssStyle.fabricStyle
      && cssStyle.fabricStyle.key && cssStyle.fabricStyle.key !== 'null' && cssStyle.fabricStyle.key !== 'undefined'
      && cssStyle.fabricStyle.style && cssStyle.fabricStyle.style !== 'null' && cssStyle.fabricStyle.style !== 'undefined') {
      try {
        defaultFabricStyle = JSON.parse(cssStyle.fabricStyle.style);
      } catch (error) {
        defaultFabricStyle = {};
      }
    }


    for (const [key, val] of Object.entries(cssStyle)) {
      if (key === 'overflowBackground') {
        continue;
      }
      if (key === 'fabricStyle') {
        if (val.key && val.key !== 'null' && val.key !== 'undefined') {
          output.textStyleKey = val.key;
        } else if (val.style && val.style !== 'null' && val.style !== 'undefined') {
          let parsedStyle = {};
          try {
            parsedStyle = JSON.parse(val.style);
          } catch (error) {
            parsedStyle = {};
          }
          output = Object.assign(parsedStyle, output);
        }
      } else {
        ref = map.get(key)
        if (!ref) {
          console.warn('Unmapped css property ' + key + ' -> ' + val)
          continue
        }

        const _value = ref.value(val);
        if (_value && defaultFabricStyle[ref.name] != _value) {
          output[ref.name] = ref.value(val);
        }
      }
    }

    //console.log('css to fabric style', output)

    return output;
  },

  fabricStyleToCss(fabricStyle, swatches, applyMaxFontSize = true) {
    if (!fabricStyle) {
      return '';
    }
    let fs = typeof fabricStyle === 'string' ? JSON.parse(fabricStyle) : fabricStyle;
    let deco = 'none';
    if (fs.underline) {
      deco = 'underline';
    }
    if (fs.overline) {
      deco = deco != 'none' ? deco + ' overline' : 'overline';
    }
    if (fs.linethrough) {
      deco = deco != 'none' ? deco + ' line-through' : 'line-through';
    }

    const fillSwatch = swatches ? swatches.find(value => value.name === fs.fill) : undefined,
      backgroundSwatch = swatches ? swatches.find(value => value.name === fs.textBackgroundColor) : undefined;

    let css = {
      color: fillSwatch ? fillSwatch.color : fs.fill,
      "font-family": fs.fontFamily,
      "font-weight": fs.fontWeight || 'normal',
      "font-size": applyMaxFontSize ? Math.max(10, Math.min(30, fs.fontSize / 1.75)) + 'px' : (fs.fontSize / 1.75 + 'px'),

      "font-style": fs.fontStyle,
      "text-decoration": deco,
      "background-color": backgroundSwatch ? backgroundSwatch.color : fs.textBackgroundColor,
      "text-align": fs.textAlign
    };

    for (let key of Object.keys(css)) {
      if (css[key] === '' || css[key] === undefined || css[key] === null) {
        delete css[key];
      }
    }

    return css;
  },

  fabricStyleToQuillEditor(fabricStyle) {
    if (!fabricStyle) {
      return {};
    }
    let fs = typeof fabricStyle === 'string' ? JSON.parse(fabricStyle) : fabricStyle;

    let formatText = {
      color: fs.fill,
      background: fs.textBackgroundColor,
      bold: fs.fontWeight && fs.fontWeight === 'bold' ? true : undefined,
      italic: fs.fontStyle && fs.fontStyle === "italic" ? true : undefined,
      strike: fs.linethrough ? true : undefined,
      underline: fs.underline ? true : undefined,
    };

    let formatLine = {
      textAlign: fs.textAlign
    }

    for (let key of Object.keys(formatText)) {
      if (formatText[key] === '' || formatText[key] === undefined || formatText[key] === null) {
        delete formatText[key];
      }
    }

    for (let key of Object.keys(formatLine)) {
      if (formatLine[key] === '' || formatLine[key] === undefined || formatLine[key] === null) {
        delete formatLine[key];
      }
    }

    return {
      formatText: Object.keys(formatText).length > 0 ? formatText : null,
      formatLine: Object.keys(formatLine).length > 0 ? formatLine : null,
      textStyleKey: fs.textStyleKey,
    };
  }
};
