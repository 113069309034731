<template>
  <div>
    <el-row type="flex" justify="center" align="middle">
      <el-col :span="8" type="flex" align="middle">
        <div
          class="mt-1"
          v-if="!inlineDisplay"
          v-t="'public.edit_magazine.select_link_to_page'"
        ></div>
        <el-select
          :class="{
            'mt-1': !inlineDisplay,
            'el-col-24': inlineDisplay,
          }"
          v-model="linkToPage"
          :placeholder="
            inlineDisplay
              ? $t('public.edit_magazine.select_link_to_page')
              : $t('public.edit_magazine.please_select_page')
          "
          @change="linkToUrl = null"
          clearable
          popper-class="select-page-list"
        >
          <el-option
            v-for="pageToLink in pagesToLink"
            :key="'linkto_' + pageToLink.value"
            :label="pageToLink.label"
            :value="pageToLink.value"
          >
            <MzPageImage :page="pageToLink.page" />
            <span class="ml-1">{{ pageToLink.label }}</span>
          </el-option>
        </el-select>
      </el-col>

      <el-col :span="inlineDisplay ? 4 : 6" type="flex" align="middle">
        <span
          :class="{
            'text-style-1': !inlineDisplay,
            'text-grey-light': !inlineDisplay,
            'text-grey': inlineDisplay,
          }"
          v-t="'public.edit_magazine.or'"
        ></span>
      </el-col>

      <el-col :span="inlineDisplay ? 11 : 9" type="flex" align="middle">
        <div
          class="mt-1"
          v-if="!inlineDisplay"
          v-t="'public.edit_magazine.set_link_to_url'"
        ></div>
        <el-input
          :class="{
            'mt-1': !inlineDisplay,
            'el-col-24': inlineDisplay,
          }"
          v-model="linkToUrl"
          @input="linkToPage = null"
          clearable
          :placeholder="
            inlineDisplay
              ? $t('public.edit_magazine.set_link_to_url')
              : $t('public.edit_magazine.set_link_to_url_placeholder')
          "
        ></el-input>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import Validators from "~/utils/validators";

import MzPageImage from "~/components/page/MzPageImage";

export default {
  name: "AddLinkForm",
  components: { MzPageImage },

  props: ["defaultLink", "inlineDisplay"],

  data() {
    return {
      linkToPage: null,
      linkToUrl: null,
    };
  },
  computed: {
    ...mapState("magazine", ["magazine"]),
    ...mapState("page", ["page"]),
    ...mapGetters("magazine", ["pageNameMap"]),

    isValid() {
      if (
        this.linkToUrl &&
        Validators.isUrl(this.linkToUrl) === false &&
        Validators.isEmail(this.linkToUrl) === false
      ) {
        return false;
      }

      return true;
    },
    computedLink() {
      const _link = this.linkToPage ? this.linkToPage : this.linkToUrl;
      if (!_link) {
        return false;
      } else {
        if (this.linkToPage) {
          const pageData = this.pagesToLink.find(
            (page) => page.value == this.linkToPage
          );
          return {
            href: _link,
            title: pageData ? pageData.label : _link,
          };
        } else if (Validators.isEmail(_link)) {
          return {
            href: "mailto:" + _link,
            title: _link,
          };
        } else {
          return {
            href: _link,
            title: _link,
          };
        }
      }
    },
    pagesToLink() {
      return this.magazine.pageOrder.reduce((list, page) => {
        if (page._id !== this.page._id) {
          if (page.double) {
            list.push({
              page,
              label: this.pageNameMap[page._id + "_left"],
              value: "#page_" + page._id + "_left",
            });
            list.push({
              page,
              label: this.pageNameMap[page._id + "_right"],
              value: "#page_" + page._id + "_right",
            });
          } else {
            list.push({
              page,
              label: this.pageNameMap[page._id],
              value: "#page_" + page._id,
            });
          }
        }
        return list;
      }, []);
    },
  },
  mounted() {
    this.updateLinkFromDefaultLink();
  },
  methods: {
    updateLinkFromDefaultLink() {
      this.linkToUrl = null;
      this.linkToPage = null;

      if (this.defaultLink) {
        if (this.defaultLink.indexOf("#") === 0) {
          this.linkToPage = this.defaultLink;
        } else if (this.defaultLink.indexOf("mailto:") === 0) {
          this.linkToUrl = this.defaultLink.substr(7);
        } else {
          this.linkToUrl = this.defaultLink;
        }
      }
    },
  },
  watch: {
    defaultLink(value) {
      console.log("defaultLink", value);
      this.updateLinkFromDefaultLink();
    },
  },
};
</script>


<style scoped lang="scss">
@import "~assets/css/mz-variables.scss";
.select-page-list {
  & .el-select-dropdown__item {
    height: 100px;
    display: flex;
    align-items: center;
  }
}
</style>