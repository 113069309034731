<template>
  <div>
    <h3 class="header">{{ $t("public.notifications.menu.title") }}</h3>

    <el-tabs v-model="activeTab" class="notification-nav">
      <el-tab-pane name="magazine">
        <span slot="label">
          {{ $t("public.notifications.menu.magazineTab") }}
          <el-badge :value="unreadMagazinesCount" type="info" class="ml-0">
          </el-badge>
        </span>

        <div v-if="magazinesList.length > 0">
          <template v-for="notification in magazinesList">
            <Notification
              class="notification"
              :key="notification._id"
              :notification="notification"
            />
          </template>
        </div>
        <div v-else class="empty-list">
          {{ $t("public.notifications.menu.empty") }}
        </div>
      </el-tab-pane>

      <el-tab-pane name="announcement">
        <span slot="label">
          {{ $t("public.notifications.menu.annoncementTab") }}
          <el-badge :value="unreadAnnoucementsCount" type="info" class="ml-0">
          </el-badge>
        </span>

        <div v-if="annoucementsList.length > 0">
          <template v-for="notification in annoucementsList">
            <Notification
              class="notification"
              :key="notification._id"
              :notification="notification"
            />
          </template>
        </div>
        <div v-else class="empty-list">
          {{ $t("public.notifications.menu.empty") }}
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import { NotificationTypes } from "~/common/data/NotificationTypes";
import Notification from "./notifications/Notification";

export default {
  name: "NotificationMenu",
  components: {
    Notification,
  },

  props: ["isVisible"],

  data() {
    return {
      activeTab: "",
    };
  },

  computed: {
    ...mapState("notification", ["notifications"]),

    magazinesList() {
      if (!this.notifications) {
        return [];
      }

      return this.notifications.filter((notif) => {
        return notif.type !== NotificationTypes.MzScheduledNotification;
      });
    },
    unreadMagazinesCount() {
      return this.magazinesList.filter((notif) => notif.read === false).length;
    },
    annoucementsList() {
      if (!this.notifications) {
        return [];
      }

      return this.notifications.filter((notif) => {
        return notif.type === NotificationTypes.MzScheduledNotification;
      });
    },
    unreadAnnoucementsCount() {
      return this.annoucementsList.filter((notif) => notif.read === false)
        .length;
    },
  },
  mounted() {
    this.loadNotifications();
  },
  methods: {
    ...mapActions({
      loadNotifications: "notification/LOAD_NOTIFICATIONS",
      setReadNotification: "notification/SET_READ_NOTIFICATION",
    }),
  },
  watch: {
    isVisible(value) {
      if (value) {
        this.activeTab = "magazine";
      } else {
        const unreadNotifIds = this.notifications
          .filter((notif) => notif.read === false)
          .map((notif) => notif._id);
        if (unreadNotifIds && unreadNotifIds.length > 0) {
          this.setReadNotification({ notificationIds: unreadNotifIds });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~assets/css/mz-variables.scss";

.header {
  margin: 1rem 1rem 0.5rem 1rem;
}

.empty-list {
  color: $public-color-grey-1;
  margin: 1rem;
}

/deep/.notification-nav {
  & .el-tabs__header {
    margin: 0 1rem;
    padding: 0;
  }

  & .el-tabs__content {
    max-height: 50vh;
    overflow: auto;
    border-top: 1px solid #dddfe6;
  }

  & .el-tabs__item {
    padding: 0 1rem;

    // & span {
    //   margin: 0rem 1rem 0rem 0rem;
    // }

    & .el-badge {
      padding-top: 2px;

      & .el-badge__content {
        font-size: 10px;
        height: 16px;
        line-height: 14px;
      }
    }
  }

  & .el-tabs__nav-wrap::after {
    display: none;
  }

  & .el-tabs__active-bar {
    height: 1px;
    border-radius: 2px;
    background-color: $public-color-blue;
    border: solid 1px $public-color-blue;
  }
}
</style>