var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',{attrs:{"type":"flex","justify":"center","align":"middle"}},[_c('el-col',{attrs:{"span":8,"type":"flex","align":"middle"}},[(!_vm.inlineDisplay)?_c('div',{directives:[{name:"t",rawName:"v-t",value:('public.edit_magazine.select_link_to_page'),expression:"'public.edit_magazine.select_link_to_page'"}],staticClass:"mt-1"}):_vm._e(),_vm._v(" "),_c('el-select',{class:{
          'mt-1': !_vm.inlineDisplay,
          'el-col-24': _vm.inlineDisplay,
        },attrs:{"placeholder":_vm.inlineDisplay
            ? _vm.$t('public.edit_magazine.select_link_to_page')
            : _vm.$t('public.edit_magazine.please_select_page'),"clearable":"","popper-class":"select-page-list"},on:{"change":function($event){_vm.linkToUrl = null}},model:{value:(_vm.linkToPage),callback:function ($$v) {_vm.linkToPage=$$v},expression:"linkToPage"}},_vm._l((_vm.pagesToLink),function(pageToLink){return _c('el-option',{key:'linkto_' + pageToLink.value,attrs:{"label":pageToLink.label,"value":pageToLink.value}},[_c('MzPageImage',{attrs:{"page":pageToLink.page}}),_vm._v(" "),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(pageToLink.label))])],1)}),1)],1),_vm._v(" "),_c('el-col',{attrs:{"span":_vm.inlineDisplay ? 4 : 6,"type":"flex","align":"middle"}},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('public.edit_magazine.or'),expression:"'public.edit_magazine.or'"}],class:{
          'text-style-1': !_vm.inlineDisplay,
          'text-grey-light': !_vm.inlineDisplay,
          'text-grey': _vm.inlineDisplay,
        }})]),_vm._v(" "),_c('el-col',{attrs:{"span":_vm.inlineDisplay ? 11 : 9,"type":"flex","align":"middle"}},[(!_vm.inlineDisplay)?_c('div',{directives:[{name:"t",rawName:"v-t",value:('public.edit_magazine.set_link_to_url'),expression:"'public.edit_magazine.set_link_to_url'"}],staticClass:"mt-1"}):_vm._e(),_vm._v(" "),_c('el-input',{class:{
          'mt-1': !_vm.inlineDisplay,
          'el-col-24': _vm.inlineDisplay,
        },attrs:{"clearable":"","placeholder":_vm.inlineDisplay
            ? _vm.$t('public.edit_magazine.set_link_to_url')
            : _vm.$t('public.edit_magazine.set_link_to_url_placeholder')},on:{"input":function($event){_vm.linkToPage = null}},model:{value:(_vm.linkToUrl),callback:function ($$v) {_vm.linkToUrl=$$v},expression:"linkToUrl"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }