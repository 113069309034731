import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d6017a90 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _070a6850 = () => interopDefault(import('../pages/client/index.vue' /* webpackChunkName: "pages/client/index" */))
const _e23b535c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _4264d339 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _a7ef17a0 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _e2c23982 = () => interopDefault(import('../pages/admin/communication/index.vue' /* webpackChunkName: "pages/admin/communication/index" */))
const _62676af0 = () => interopDefault(import('../pages/admin/settings/index.vue' /* webpackChunkName: "pages/admin/settings/index" */))
const _5d043a87 = () => interopDefault(import('../pages/admin/template/index.vue' /* webpackChunkName: "pages/admin/template/index" */))
const _599d8904 = () => interopDefault(import('../pages/admin/tools/index.vue' /* webpackChunkName: "pages/admin/tools/index" */))
const _3ef8b6f8 = () => interopDefault(import('../pages/admin/tracking/index.vue' /* webpackChunkName: "pages/admin/tracking/index" */))
const _2835c536 = () => interopDefault(import('../pages/client/offer/index.vue' /* webpackChunkName: "pages/client/offer/index" */))
const _5972c9e0 = () => interopDefault(import('../pages/client/organization/index.vue' /* webpackChunkName: "pages/client/organization/index" */))
const _3598dc78 = () => interopDefault(import('../pages/client/user/index.vue' /* webpackChunkName: "pages/client/user/index" */))
const _358dd544 = () => interopDefault(import('../pages/client/workspace/index.vue' /* webpackChunkName: "pages/client/workspace/index" */))
const _6abdf3f8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _02f28b75 = () => interopDefault(import('../pages/login/callback.vue' /* webpackChunkName: "pages/login/callback" */))
const _e70abe00 = () => interopDefault(import('../pages/password/forgot/index.vue' /* webpackChunkName: "pages/password/forgot/index" */))
const _6049cb4a = () => interopDefault(import('../pages/admin/authStrategy/list/index.vue' /* webpackChunkName: "pages/admin/authStrategy/list/index" */))
const _1d4936b4 = () => interopDefault(import('../pages/admin/communication/message.vue' /* webpackChunkName: "pages/admin/communication/message" */))
const _57e538e4 = () => interopDefault(import('../pages/admin/communication/notification.vue' /* webpackChunkName: "pages/admin/communication/notification" */))
const _4bccc29f = () => interopDefault(import('../pages/admin/format/list/index.vue' /* webpackChunkName: "pages/admin/format/list/index" */))
const _765c25e2 = () => interopDefault(import('../pages/admin/magazine/list/index.vue' /* webpackChunkName: "pages/admin/magazine/list/index" */))
const _1fd1113e = () => interopDefault(import('../pages/admin/offer/list/index.vue' /* webpackChunkName: "pages/admin/offer/list/index" */))
const _00c6f649 = () => interopDefault(import('../pages/admin/order/list.vue' /* webpackChunkName: "pages/admin/order/list" */))
const _ae56093a = () => interopDefault(import('../pages/admin/organization/list/index.vue' /* webpackChunkName: "pages/admin/organization/list/index" */))
const _75a73031 = () => interopDefault(import('../pages/admin/settings/maintenance.vue' /* webpackChunkName: "pages/admin/settings/maintenance" */))
const _140b4788 = () => interopDefault(import('../pages/admin/template/list/index.vue' /* webpackChunkName: "pages/admin/template/list/index" */))
const _1b57c000 = () => interopDefault(import('../pages/admin/tools/exportMagazineThumb.vue' /* webpackChunkName: "pages/admin/tools/exportMagazineThumb" */))
const _675cf0a8 = () => interopDefault(import('../pages/admin/users/adminList.vue' /* webpackChunkName: "pages/admin/users/adminList" */))
const _4ae0b20c = () => interopDefault(import('../pages/admin/users/clientList.vue' /* webpackChunkName: "pages/admin/users/clientList" */))
const _48829ce5 = () => interopDefault(import('../pages/admin/workspace/list/index.vue' /* webpackChunkName: "pages/admin/workspace/list/index" */))
const _863a4aaa = () => interopDefault(import('../pages/client/order/cart.vue' /* webpackChunkName: "pages/client/order/cart" */))
const _491670c4 = () => interopDefault(import('../pages/client/order/checkoutFinish.vue' /* webpackChunkName: "pages/client/order/checkoutFinish" */))
const _0cb42129 = () => interopDefault(import('../pages/client/order/list.vue' /* webpackChunkName: "pages/client/order/list" */))
const _24580853 = () => interopDefault(import('../pages/admin/organization/list/byGroupOffer.vue' /* webpackChunkName: "pages/admin/organization/list/byGroupOffer" */))
const _081f33d0 = () => interopDefault(import('../pages/client/magazine/create/chooseMagazineName.vue' /* webpackChunkName: "pages/client/magazine/create/chooseMagazineName" */))
const _4b3f04b8 = () => interopDefault(import('../pages/client/magazine/create/selectTemplate.vue' /* webpackChunkName: "pages/client/magazine/create/selectTemplate" */))
const _3c50be24 = () => interopDefault(import('../pages/admin/library/edit/_libraryId.vue' /* webpackChunkName: "pages/admin/library/edit/_libraryId" */))
const _5becafea = () => interopDefault(import('../pages/admin/magazine/edit/_magazineId.vue' /* webpackChunkName: "pages/admin/magazine/edit/_magazineId" */))
const _989f25ec = () => interopDefault(import('../pages/admin/organization/edit/_organizationId.vue' /* webpackChunkName: "pages/admin/organization/edit/_organizationId" */))
const _5d161a72 = () => interopDefault(import('../pages/admin/style/edit/_themeId.vue' /* webpackChunkName: "pages/admin/style/edit/_themeId" */))
const _d370d1ac = () => interopDefault(import('../pages/admin/template/edit/_templateId.vue' /* webpackChunkName: "pages/admin/template/edit/_templateId" */))
const _df89fd8a = () => interopDefault(import('../pages/admin/textblocktype/edit/_themeId.vue' /* webpackChunkName: "pages/admin/textblocktype/edit/_themeId" */))
const _44b19c8a = () => interopDefault(import('../pages/admin/theme/edit/_themeId.vue' /* webpackChunkName: "pages/admin/theme/edit/_themeId" */))
const _532297f3 = () => interopDefault(import('../pages/admin/theme/list/_templateId.vue' /* webpackChunkName: "pages/admin/theme/list/_templateId" */))
const _47050291 = () => interopDefault(import('../pages/admin/users/edit/_userId.vue' /* webpackChunkName: "pages/admin/users/edit/_userId" */))
const _2ffefbbc = () => interopDefault(import('../pages/admin/workspace/edit/_workspaceId.vue' /* webpackChunkName: "pages/admin/workspace/edit/_workspaceId" */))
const _68feffe6 = () => interopDefault(import('../pages/client/magazine/edit/_magazineId/index.vue' /* webpackChunkName: "pages/client/magazine/edit/_magazineId/index" */))
const _669c58fa = () => interopDefault(import('../pages/client/magazine/edit/_magazineId/collaboration/index.vue' /* webpackChunkName: "pages/client/magazine/edit/_magazineId/collaboration/index" */))
const _09adc36a = () => interopDefault(import('../pages/client/magazine/edit/_magazineId/publication/index.vue' /* webpackChunkName: "pages/client/magazine/edit/_magazineId/publication/index" */))
const _8b5f8fd2 = () => interopDefault(import('../pages/admin/palette/edit/_templateId/_themeId.vue' /* webpackChunkName: "pages/admin/palette/edit/_templateId/_themeId" */))
const _a67ef458 = () => interopDefault(import('../pages/password/reset/_token.vue' /* webpackChunkName: "pages/password/reset/_token" */))
const _426dd580 = () => interopDefault(import('../pages/admin/editor/_magazineId/_paletteId.vue' /* webpackChunkName: "pages/admin/editor/_magazineId/_paletteId" */))
const _1231a71f = () => interopDefault(import('../pages/login/_providerKey.vue' /* webpackChunkName: "pages/login/_providerKey" */))
const _5acda98c = () => interopDefault(import('../pages/register/_token.vue' /* webpackChunkName: "pages/register/_token" */))
const _90e61f8a = () => interopDefault(import('../pages/viewer/_magazineId.vue' /* webpackChunkName: "pages/viewer/_magazineId" */))
const _66062661 = () => interopDefault(import('../pages/join/_accessId/_nickname.vue' /* webpackChunkName: "pages/join/_accessId/_nickname" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _d6017a90,
    name: "admin___en___default"
  }, {
    path: "/client",
    component: _070a6850,
    name: "client___en___default"
  }, {
    path: "/login",
    component: _e23b535c,
    name: "login___en___default"
  }, {
    path: "/maintenance",
    component: _4264d339,
    name: "maintenance___en___default"
  }, {
    path: "/register",
    component: _a7ef17a0,
    name: "register___en___default"
  }, {
    path: "/admin/communication",
    component: _e2c23982,
    name: "admin-communication___en___default"
  }, {
    path: "/admin/settings",
    component: _62676af0,
    name: "admin-settings___en___default"
  }, {
    path: "/admin/template",
    component: _5d043a87,
    name: "admin-template___en___default"
  }, {
    path: "/admin/tools",
    component: _599d8904,
    name: "admin-tools___en___default"
  }, {
    path: "/admin/tracking",
    component: _3ef8b6f8,
    name: "admin-tracking___en___default"
  }, {
    path: "/client/offer",
    component: _2835c536,
    name: "client-offer___en___default"
  }, {
    path: "/client/organization",
    component: _5972c9e0,
    name: "client-organization___en___default"
  }, {
    path: "/client/user",
    component: _3598dc78,
    name: "client-user___en___default"
  }, {
    path: "/client/workspace",
    component: _358dd544,
    name: "client-workspace___en___default"
  }, {
    path: "/en/",
    component: _6abdf3f8,
    name: "index___en"
  }, {
    path: "/en/admin",
    component: _d6017a90,
    name: "admin___en"
  }, {
    path: "/en/client",
    component: _070a6850,
    name: "client___en"
  }, {
    path: "/en/login",
    component: _e23b535c,
    name: "login___en"
  }, {
    path: "/en/maintenance",
    component: _4264d339,
    name: "maintenance___en"
  }, {
    path: "/en/register",
    component: _a7ef17a0,
    name: "register___en"
  }, {
    path: "/fr/",
    component: _6abdf3f8,
    name: "index___fr"
  }, {
    path: "/fr/admin",
    component: _d6017a90,
    name: "admin___fr"
  }, {
    path: "/fr/client",
    component: _070a6850,
    name: "client___fr"
  }, {
    path: "/fr/login",
    component: _e23b535c,
    name: "login___fr"
  }, {
    path: "/fr/maintenance",
    component: _4264d339,
    name: "maintenance___fr"
  }, {
    path: "/fr/register",
    component: _a7ef17a0,
    name: "register___fr"
  }, {
    path: "/login/callback",
    component: _02f28b75,
    name: "login-callback___en___default"
  }, {
    path: "/password/forgot",
    component: _e70abe00,
    name: "password-forgot___en___default"
  }, {
    path: "/admin/authStrategy/list",
    component: _6049cb4a,
    name: "admin-authStrategy-list___en___default"
  }, {
    path: "/admin/communication/message",
    component: _1d4936b4,
    name: "admin-communication-message___en___default"
  }, {
    path: "/admin/communication/notification",
    component: _57e538e4,
    name: "admin-communication-notification___en___default"
  }, {
    path: "/admin/format/list",
    component: _4bccc29f,
    name: "admin-format-list___en___default"
  }, {
    path: "/admin/magazine/list",
    component: _765c25e2,
    name: "admin-magazine-list___en___default"
  }, {
    path: "/admin/offer/list",
    component: _1fd1113e,
    name: "admin-offer-list___en___default"
  }, {
    path: "/admin/order/list",
    component: _00c6f649,
    name: "admin-order-list___en___default"
  }, {
    path: "/admin/organization/list",
    component: _ae56093a,
    name: "admin-organization-list___en___default"
  }, {
    path: "/admin/settings/maintenance",
    component: _75a73031,
    name: "admin-settings-maintenance___en___default"
  }, {
    path: "/admin/template/list",
    component: _140b4788,
    name: "admin-template-list___en___default"
  }, {
    path: "/admin/tools/exportMagazineThumb",
    component: _1b57c000,
    name: "admin-tools-exportMagazineThumb___en___default"
  }, {
    path: "/admin/users/adminList",
    component: _675cf0a8,
    name: "admin-users-adminList___en___default"
  }, {
    path: "/admin/users/clientList",
    component: _4ae0b20c,
    name: "admin-users-clientList___en___default"
  }, {
    path: "/admin/workspace/list",
    component: _48829ce5,
    name: "admin-workspace-list___en___default"
  }, {
    path: "/client/order/cart",
    component: _863a4aaa,
    name: "client-order-cart___en___default"
  }, {
    path: "/client/order/checkoutFinish",
    component: _491670c4,
    name: "client-order-checkoutFinish___en___default"
  }, {
    path: "/client/order/list",
    component: _0cb42129,
    name: "client-order-list___en___default"
  }, {
    path: "/en/admin/communication",
    component: _e2c23982,
    name: "admin-communication___en"
  }, {
    path: "/en/admin/settings",
    component: _62676af0,
    name: "admin-settings___en"
  }, {
    path: "/en/admin/template",
    component: _5d043a87,
    name: "admin-template___en"
  }, {
    path: "/en/admin/tools",
    component: _599d8904,
    name: "admin-tools___en"
  }, {
    path: "/en/admin/tracking",
    component: _3ef8b6f8,
    name: "admin-tracking___en"
  }, {
    path: "/en/client/offer",
    component: _2835c536,
    name: "client-offer___en"
  }, {
    path: "/en/client/organization",
    component: _5972c9e0,
    name: "client-organization___en"
  }, {
    path: "/en/client/user",
    component: _3598dc78,
    name: "client-user___en"
  }, {
    path: "/en/client/workspace",
    component: _358dd544,
    name: "client-workspace___en"
  }, {
    path: "/en/login/callback",
    component: _02f28b75,
    name: "login-callback___en"
  }, {
    path: "/en/password/forgot",
    component: _e70abe00,
    name: "password-forgot___en"
  }, {
    path: "/fr/admin/communication",
    component: _e2c23982,
    name: "admin-communication___fr"
  }, {
    path: "/fr/admin/settings",
    component: _62676af0,
    name: "admin-settings___fr"
  }, {
    path: "/fr/admin/template",
    component: _5d043a87,
    name: "admin-template___fr"
  }, {
    path: "/fr/admin/tools",
    component: _599d8904,
    name: "admin-tools___fr"
  }, {
    path: "/fr/admin/tracking",
    component: _3ef8b6f8,
    name: "admin-tracking___fr"
  }, {
    path: "/fr/client/offer",
    component: _2835c536,
    name: "client-offer___fr"
  }, {
    path: "/fr/client/organization",
    component: _5972c9e0,
    name: "client-organization___fr"
  }, {
    path: "/fr/client/user",
    component: _3598dc78,
    name: "client-user___fr"
  }, {
    path: "/fr/client/workspace",
    component: _358dd544,
    name: "client-workspace___fr"
  }, {
    path: "/fr/login/callback",
    component: _02f28b75,
    name: "login-callback___fr"
  }, {
    path: "/fr/password/forgot",
    component: _e70abe00,
    name: "password-forgot___fr"
  }, {
    path: "/admin/organization/list/byGroupOffer",
    component: _24580853,
    name: "admin-organization-list-byGroupOffer___en___default"
  }, {
    path: "/client/magazine/create/chooseMagazineName",
    component: _081f33d0,
    name: "client-magazine-create-chooseMagazineName___en___default"
  }, {
    path: "/client/magazine/create/selectTemplate",
    component: _4b3f04b8,
    name: "client-magazine-create-selectTemplate___en___default"
  }, {
    path: "/en/admin/authStrategy/list",
    component: _6049cb4a,
    name: "admin-authStrategy-list___en"
  }, {
    path: "/en/admin/communication/message",
    component: _1d4936b4,
    name: "admin-communication-message___en"
  }, {
    path: "/en/admin/communication/notification",
    component: _57e538e4,
    name: "admin-communication-notification___en"
  }, {
    path: "/en/admin/format/list",
    component: _4bccc29f,
    name: "admin-format-list___en"
  }, {
    path: "/en/admin/magazine/list",
    component: _765c25e2,
    name: "admin-magazine-list___en"
  }, {
    path: "/en/admin/offer/list",
    component: _1fd1113e,
    name: "admin-offer-list___en"
  }, {
    path: "/en/admin/order/list",
    component: _00c6f649,
    name: "admin-order-list___en"
  }, {
    path: "/en/admin/organization/list",
    component: _ae56093a,
    name: "admin-organization-list___en"
  }, {
    path: "/en/admin/settings/maintenance",
    component: _75a73031,
    name: "admin-settings-maintenance___en"
  }, {
    path: "/en/admin/template/list",
    component: _140b4788,
    name: "admin-template-list___en"
  }, {
    path: "/en/admin/tools/exportMagazineThumb",
    component: _1b57c000,
    name: "admin-tools-exportMagazineThumb___en"
  }, {
    path: "/en/admin/users/adminList",
    component: _675cf0a8,
    name: "admin-users-adminList___en"
  }, {
    path: "/en/admin/users/clientList",
    component: _4ae0b20c,
    name: "admin-users-clientList___en"
  }, {
    path: "/en/admin/workspace/list",
    component: _48829ce5,
    name: "admin-workspace-list___en"
  }, {
    path: "/en/client/order/cart",
    component: _863a4aaa,
    name: "client-order-cart___en"
  }, {
    path: "/en/client/order/checkoutFinish",
    component: _491670c4,
    name: "client-order-checkoutFinish___en"
  }, {
    path: "/en/client/order/list",
    component: _0cb42129,
    name: "client-order-list___en"
  }, {
    path: "/fr/admin/authStrategy/list",
    component: _6049cb4a,
    name: "admin-authStrategy-list___fr"
  }, {
    path: "/fr/admin/communication/message",
    component: _1d4936b4,
    name: "admin-communication-message___fr"
  }, {
    path: "/fr/admin/communication/notification",
    component: _57e538e4,
    name: "admin-communication-notification___fr"
  }, {
    path: "/fr/admin/format/list",
    component: _4bccc29f,
    name: "admin-format-list___fr"
  }, {
    path: "/fr/admin/magazine/list",
    component: _765c25e2,
    name: "admin-magazine-list___fr"
  }, {
    path: "/fr/admin/offer/list",
    component: _1fd1113e,
    name: "admin-offer-list___fr"
  }, {
    path: "/fr/admin/order/list",
    component: _00c6f649,
    name: "admin-order-list___fr"
  }, {
    path: "/fr/admin/organization/list",
    component: _ae56093a,
    name: "admin-organization-list___fr"
  }, {
    path: "/fr/admin/settings/maintenance",
    component: _75a73031,
    name: "admin-settings-maintenance___fr"
  }, {
    path: "/fr/admin/template/list",
    component: _140b4788,
    name: "admin-template-list___fr"
  }, {
    path: "/fr/admin/tools/exportMagazineThumb",
    component: _1b57c000,
    name: "admin-tools-exportMagazineThumb___fr"
  }, {
    path: "/fr/admin/users/adminList",
    component: _675cf0a8,
    name: "admin-users-adminList___fr"
  }, {
    path: "/fr/admin/users/clientList",
    component: _4ae0b20c,
    name: "admin-users-clientList___fr"
  }, {
    path: "/fr/admin/workspace/list",
    component: _48829ce5,
    name: "admin-workspace-list___fr"
  }, {
    path: "/fr/client/order/cart",
    component: _863a4aaa,
    name: "client-order-cart___fr"
  }, {
    path: "/fr/client/order/checkoutFinish",
    component: _491670c4,
    name: "client-order-checkoutFinish___fr"
  }, {
    path: "/fr/client/order/list",
    component: _0cb42129,
    name: "client-order-list___fr"
  }, {
    path: "/en/admin/organization/list/byGroupOffer",
    component: _24580853,
    name: "admin-organization-list-byGroupOffer___en"
  }, {
    path: "/en/client/magazine/create/chooseMagazineName",
    component: _081f33d0,
    name: "client-magazine-create-chooseMagazineName___en"
  }, {
    path: "/en/client/magazine/create/selectTemplate",
    component: _4b3f04b8,
    name: "client-magazine-create-selectTemplate___en"
  }, {
    path: "/fr/admin/organization/list/byGroupOffer",
    component: _24580853,
    name: "admin-organization-list-byGroupOffer___fr"
  }, {
    path: "/fr/client/magazine/create/chooseMagazineName",
    component: _081f33d0,
    name: "client-magazine-create-chooseMagazineName___fr"
  }, {
    path: "/fr/client/magazine/create/selectTemplate",
    component: _4b3f04b8,
    name: "client-magazine-create-selectTemplate___fr"
  }, {
    path: "/en/admin/library/edit/:libraryId?",
    component: _3c50be24,
    name: "admin-library-edit-libraryId___en"
  }, {
    path: "/en/admin/magazine/edit/:magazineId?",
    component: _5becafea,
    name: "admin-magazine-edit-magazineId___en"
  }, {
    path: "/en/admin/organization/edit/:organizationId?",
    component: _989f25ec,
    name: "admin-organization-edit-organizationId___en"
  }, {
    path: "/en/admin/style/edit/:themeId?",
    component: _5d161a72,
    name: "admin-style-edit-themeId___en"
  }, {
    path: "/en/admin/template/edit/:templateId?",
    component: _d370d1ac,
    name: "admin-template-edit-templateId___en"
  }, {
    path: "/en/admin/textblocktype/edit/:themeId?",
    component: _df89fd8a,
    name: "admin-textblocktype-edit-themeId___en"
  }, {
    path: "/en/admin/theme/edit/:themeId?",
    component: _44b19c8a,
    name: "admin-theme-edit-themeId___en"
  }, {
    path: "/en/admin/theme/list/:templateId?",
    component: _532297f3,
    name: "admin-theme-list-templateId___en"
  }, {
    path: "/en/admin/users/edit/:userId?",
    component: _47050291,
    name: "admin-users-edit-userId___en"
  }, {
    path: "/en/admin/workspace/edit/:workspaceId?",
    component: _2ffefbbc,
    name: "admin-workspace-edit-workspaceId___en"
  }, {
    path: "/en/client/magazine/edit/:magazineId?",
    component: _68feffe6,
    name: "client-magazine-edit-magazineId___en"
  }, {
    path: "/fr/admin/library/edit/:libraryId?",
    component: _3c50be24,
    name: "admin-library-edit-libraryId___fr"
  }, {
    path: "/fr/admin/magazine/edit/:magazineId?",
    component: _5becafea,
    name: "admin-magazine-edit-magazineId___fr"
  }, {
    path: "/fr/admin/organization/edit/:organizationId?",
    component: _989f25ec,
    name: "admin-organization-edit-organizationId___fr"
  }, {
    path: "/fr/admin/style/edit/:themeId?",
    component: _5d161a72,
    name: "admin-style-edit-themeId___fr"
  }, {
    path: "/fr/admin/template/edit/:templateId?",
    component: _d370d1ac,
    name: "admin-template-edit-templateId___fr"
  }, {
    path: "/fr/admin/textblocktype/edit/:themeId?",
    component: _df89fd8a,
    name: "admin-textblocktype-edit-themeId___fr"
  }, {
    path: "/fr/admin/theme/edit/:themeId?",
    component: _44b19c8a,
    name: "admin-theme-edit-themeId___fr"
  }, {
    path: "/fr/admin/theme/list/:templateId?",
    component: _532297f3,
    name: "admin-theme-list-templateId___fr"
  }, {
    path: "/fr/admin/users/edit/:userId?",
    component: _47050291,
    name: "admin-users-edit-userId___fr"
  }, {
    path: "/fr/admin/workspace/edit/:workspaceId?",
    component: _2ffefbbc,
    name: "admin-workspace-edit-workspaceId___fr"
  }, {
    path: "/fr/client/magazine/edit/:magazineId?",
    component: _68feffe6,
    name: "client-magazine-edit-magazineId___fr"
  }, {
    path: "/en/client/magazine/edit/:magazineId?/collaboration",
    component: _669c58fa,
    name: "client-magazine-edit-magazineId-collaboration___en"
  }, {
    path: "/en/client/magazine/edit/:magazineId?/publication",
    component: _09adc36a,
    name: "client-magazine-edit-magazineId-publication___en"
  }, {
    path: "/fr/client/magazine/edit/:magazineId?/collaboration",
    component: _669c58fa,
    name: "client-magazine-edit-magazineId-collaboration___fr"
  }, {
    path: "/fr/client/magazine/edit/:magazineId?/publication",
    component: _09adc36a,
    name: "client-magazine-edit-magazineId-publication___fr"
  }, {
    path: "/en/admin/palette/edit/:templateId?/:themeId?",
    component: _8b5f8fd2,
    name: "admin-palette-edit-templateId-themeId___en"
  }, {
    path: "/fr/admin/palette/edit/:templateId?/:themeId?",
    component: _8b5f8fd2,
    name: "admin-palette-edit-templateId-themeId___fr"
  }, {
    path: "/admin/library/edit/:libraryId?",
    component: _3c50be24,
    name: "admin-library-edit-libraryId___en___default"
  }, {
    path: "/admin/magazine/edit/:magazineId?",
    component: _5becafea,
    name: "admin-magazine-edit-magazineId___en___default"
  }, {
    path: "/admin/organization/edit/:organizationId?",
    component: _989f25ec,
    name: "admin-organization-edit-organizationId___en___default"
  }, {
    path: "/admin/style/edit/:themeId?",
    component: _5d161a72,
    name: "admin-style-edit-themeId___en___default"
  }, {
    path: "/admin/template/edit/:templateId?",
    component: _d370d1ac,
    name: "admin-template-edit-templateId___en___default"
  }, {
    path: "/admin/textblocktype/edit/:themeId?",
    component: _df89fd8a,
    name: "admin-textblocktype-edit-themeId___en___default"
  }, {
    path: "/admin/theme/edit/:themeId?",
    component: _44b19c8a,
    name: "admin-theme-edit-themeId___en___default"
  }, {
    path: "/admin/theme/list/:templateId?",
    component: _532297f3,
    name: "admin-theme-list-templateId___en___default"
  }, {
    path: "/admin/users/edit/:userId?",
    component: _47050291,
    name: "admin-users-edit-userId___en___default"
  }, {
    path: "/admin/workspace/edit/:workspaceId?",
    component: _2ffefbbc,
    name: "admin-workspace-edit-workspaceId___en___default"
  }, {
    path: "/client/magazine/edit/:magazineId?",
    component: _68feffe6,
    name: "client-magazine-edit-magazineId___en___default"
  }, {
    path: "/en/password/reset/:token?",
    component: _a67ef458,
    name: "password-reset-token___en"
  }, {
    path: "/fr/password/reset/:token?",
    component: _a67ef458,
    name: "password-reset-token___fr"
  }, {
    path: "/client/magazine/edit/:magazineId?/collaboration",
    component: _669c58fa,
    name: "client-magazine-edit-magazineId-collaboration___en___default"
  }, {
    path: "/client/magazine/edit/:magazineId?/publication",
    component: _09adc36a,
    name: "client-magazine-edit-magazineId-publication___en___default"
  }, {
    path: "/admin/palette/edit/:templateId?/:themeId?",
    component: _8b5f8fd2,
    name: "admin-palette-edit-templateId-themeId___en___default"
  }, {
    path: "/en/admin/editor/:magazineId?/:paletteId?",
    component: _426dd580,
    name: "admin-editor-magazineId-paletteId___en"
  }, {
    path: "/fr/admin/editor/:magazineId?/:paletteId?",
    component: _426dd580,
    name: "admin-editor-magazineId-paletteId___fr"
  }, {
    path: "/en/login/:providerKey",
    component: _1231a71f,
    name: "login-providerKey___en"
  }, {
    path: "/en/register/:token",
    component: _5acda98c,
    name: "register-token___en"
  }, {
    path: "/en/viewer/:magazineId?",
    component: _90e61f8a,
    name: "viewer-magazineId___en"
  }, {
    path: "/fr/login/:providerKey",
    component: _1231a71f,
    name: "login-providerKey___fr"
  }, {
    path: "/fr/register/:token",
    component: _5acda98c,
    name: "register-token___fr"
  }, {
    path: "/fr/viewer/:magazineId?",
    component: _90e61f8a,
    name: "viewer-magazineId___fr"
  }, {
    path: "/password/reset/:token?",
    component: _a67ef458,
    name: "password-reset-token___en___default"
  }, {
    path: "/admin/editor/:magazineId?/:paletteId?",
    component: _426dd580,
    name: "admin-editor-magazineId-paletteId___en___default"
  }, {
    path: "/en/join/:accessId?/:nickname?",
    component: _66062661,
    name: "join-accessId-nickname___en"
  }, {
    path: "/fr/join/:accessId?/:nickname?",
    component: _66062661,
    name: "join-accessId-nickname___fr"
  }, {
    path: "/login/:providerKey",
    component: _1231a71f,
    name: "login-providerKey___en___default"
  }, {
    path: "/register/:token",
    component: _5acda98c,
    name: "register-token___en___default"
  }, {
    path: "/viewer/:magazineId?",
    component: _90e61f8a,
    name: "viewer-magazineId___en___default"
  }, {
    path: "/join/:accessId?/:nickname?",
    component: _66062661,
    name: "join-accessId-nickname___en___default"
  }, {
    path: "/",
    component: _6abdf3f8,
    name: "index___en___default"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
