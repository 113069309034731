<template>
  <div>
    <el-dialog
      :visible.sync="displayWaitingDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      append-to-body
    >
      <span slot="title" class="el-dialog__title">
        {{ dialogTitle }}
        <!-- <button
          @click="cancelProcess"
          type="button"
          aria-label="Close"
          class="el-dialog__headerbtn"
        >
          <i class="el-dialog__close el-icon el-icon-close"></i>
        </button> -->
      </span>

      <el-row class="row-editor">
        <el-col :span="12" class="content-text-editor">
          <RichTextEditor
            ref="richTextEditor"
            v-if="currentTextBlock && currentTextBlock._id"
            :textblock="currentTextBlock"
            :key="currentTextBlock._id"
            :backgroundProcess="true"
            @ready="onRteReady"
          />
        </el-col>
        <el-col :span="12" class="content-page-editor">
          <PageEditor
            ref="pageEditor"
            :editDisabled="true"
            :lowDefinitionImage="true"
            :backgroundProcess="true"
            @ready="onEditorReady"
          />
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col>
          <el-progress
            :text-inside="true"
            :stroke-width="26"
            :percentage="globalPercentage"
          ></el-progress>
        </el-col>
      </el-row>

      <el-row class="mt-1" v-if="checkPerm('debug:all')">
        <small>
          Pages to generate:
          {{
            this.pagesToGenerate
              ? this.currentGenerateIndex + "/" + this.pagesToGenerate.length
              : "-"
          }}
        </small>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import FontLoaderUtils from "~/common/utils/fontLoaderUtils";

import PageEditor from "~/components/editor/PageEditor";
import RichTextEditor from "../editor/RichTextEditor.vue";

export default {
  name: "RefreshMagazineTextModal",
  components: { PageEditor, RichTextEditor },

  props: [],
  data() {
    return {
      dialogTitle: "",
      displayWaitingDialog: false,
      currentGenerateIndex: 0,
      currentTextBlock: null,
      pagesToGenerate: null,
      textblockToGenerate: null,
    };
  },
  computed: {
    ...mapState("magazine", ["magazine"]),
    ...mapState("theme", ["theme"]),
    ...mapGetters("api", ["checkPerm"]),
    ...mapGetters("theme", ["getFormatedStylesForFabric"]),
    ...mapState("article", ["article", "textblockMap"]),
    ...mapGetters("article", ["getArticleOfPage"]),

    globalPercentage() {
      return this.pagesToGenerate
        ? Math.round(
            (this.currentGenerateIndex * 100) / this.pagesToGenerate.length
          )
        : 0;
    },
  },
  methods: {
    ...mapActions({
      loadMagazine: "magazine/LOAD_MAGAZINE",
      generatePageThumb: "page/GENERATE_PAGE_THUMB",
      loadArticles: "article/LOAD_ARTICLES",
      loadArticleTextblocks: "article/LOAD_ARTICLE_TEXBLOCKS",
      updatePageDataObjects: "page/UPDATE_PAGE_DATA_OBJECTS",
      savePage: "page/SAVE_PAGE",
    }),
    ...mapMutations({
      resetTextBlocks: "article/RESET_TEXBLOCKS",
      setArticleOfPage: "article/SET_ARTICLE_OF_PAGE",
      updatePageField: "page/UPDATE_FIELD",
    }),

    reloadDataAndHideDialog() {
      //force reload magazine before hide dialog
      this.loadMagazine({
        magazineId: this.magazine._id,
        loadType: "current",
      }).then(() => {
        this.hideDialog();
      });
    },
    hideDialog() {
      if (this.$refs.pageEditor) {
        this.$refs.pageEditor.unloadEditor();
      }

      this.displayWaitingDialog = false;
    },

    startRefresh(pages) {
      // if already in progress, nothing to do
      if (this.displayWaitingDialog) {
        return;
      }

      this.currentGenerateIndex = 0;
      this.dialogTitle = this.$t("public.refreshTextBlockModal.title");
      this.pagesToGenerate = pages.map((page) => ({
        page: page._id,
        palette: null,
      }));
      this.displayWaitingDialog = true;

      this.loadArticles({ magazine: this.magazine._id, page: null }).then(
        () => {
          this.startGeneratePage();
        }
      );
    },

    startGeneratePage() {
      this.currentGenerateIndex = 0;
      this.displayWaitingDialog = true;

      this.generateNextPage();
    },
    generateNextPage() {
      if (this.currentGenerateIndex < this.pagesToGenerate.length) {
        if (!this.$refs.pageEditor) {
          // bug fix wait editor is renderer
          this.displayWaitingDialog = true;
          this.$nextTick(this.generateNextPage);
          return;
        }
        const currentGenerateData =
          this.pagesToGenerate[this.currentGenerateIndex];
        this.$refs.pageEditor.loadEditorForPage(
          currentGenerateData.page,
          currentGenerateData.palette
        );
      } else {
        // force flatplan to reload current page
        this.$bus.$emit("forceReloadPageFlatplan");

        // resend action after 0.25sec
        setTimeout(() => {
          // end process
          this.reloadDataAndHideDialog();
        }, 250);
      }
    },
    onEditorReady() {
      if (!this.displayWaitingDialog) {
        // action has been cancel during loading
        return;
      }

      const currentGenerateData =
        this.pagesToGenerate[this.currentGenerateIndex];

      this.loadTextblockForPage(currentGenerateData.page);
    },

    async loadTextblockForPage(pageId) {
      // select article for pageId
      let article = this.getArticleOfPage(pageId);
      if (article) {
        // select current article
        this.setArticleOfPage(pageId);
      }

      //reset list of texblocks before loading another one
      this.resetTextBlocks();

      await this.loadArticleTextblocks({ articleId: this.article._id });

      this.textblockToGenerate = [];
      for (let textblock of Object.values(this.textblockMap)) {
        if (textblock.target && textblock.html && textblock.html.length > 0) {
          this.textblockToGenerate.push(textblock);
        }
      }

      this.generateNextTextblock();
    },
    generateNextTextblock() {
      if (this.textblockToGenerate.length > 0) {
        this.currentTextBlock = this.textblockToGenerate.shift();
      } else {
        this.currentTextBlock = null;
        this.endGenerateTextblock();
      }
    },
    onRteReady() {
      this.$refs.richTextEditor.onTextblockChanged();

      this.$nextTick(() => {
        this.generateNextTextblock();
      });
    },
    async endGenerateTextblock() {
      if (!this.displayWaitingDialog) {
        // action has been cancel during loading
        return;
      }

      // send pageData to server
      const exportPageData = this.$fabric.page.export();
      this.updatePageField({
        key: "pageData",
        value: exportPageData,
      });
      this.updatePageField({
        key: "needCompleteTextblockRefresh",
        value: false,
      });

      await this.savePage();

      // generate thumb and svg
      const currentGenerateData =
        this.pagesToGenerate[this.currentGenerateIndex];

      const svgData = this.$fabric.page.getSVG({
        fonts: FontLoaderUtils.fontListUrl([
          ...this.$fontManager.defaultFonts,
          ...this.theme.fonts,
        ]),
        styles: this.getFormatedStylesForFabric,
      });
      const thumbData = this.$fabric.page.getThumb();

      await this.generatePageThumb({
        pageId: currentGenerateData.page,
        paletteId: currentGenerateData.palette,
        isDefaultPalette:
          currentGenerateData.palette &&
          currentGenerateData.palette === this.theme.paletteList[0]._id,
        svgData,
        thumbData,
      });

      this.endGenerateCurrentPage();
    },

    endGenerateCurrentPage() {
      // generate next thumb
      this.currentGenerateIndex++;
      this.generateNextPage();
    },
    cancelProcess() {
      this.hideDialog(false);
    },
  },
};
</script>

<style scoped lang="scss">
.row-editor {
  height: 1px;
  overflow: hidden;

  & .content-page-editor {
    height: 300px;
    width: 300px;
    position: relative;
  }

  & .content-text-editor {
    height: 300px;
  }
}
</style>