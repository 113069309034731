import Vue from 'vue'
import WebFontLoader from "webfontloader";

/* google fonts */
const defaultGoogleFonts = [
  'Abril Fatface',
  'Amatic SC',
  'Anton',
  'Barlow',
  'Bebas Neue',
  'Bitter',
  'Cinzel',
  'Cormorant Garamond',
  'Crimson Text',
  'Dancing Script',
  'Fjalla One',
  'Josefin Slab',
  'Lato',
  'Libre Baskerville',
  'Livvic',
  'Lora',
  'Merriweather',
  'Montserrat',
  'Noto Sans',
  'Open Sans',
  'Oswald',
  'Pacifico',
  'Playfair Display',
  'Poppins',
  'PT Serif',
  'Raleway',
  'Roboto',
  'Source Sans Pro',
  'Ubuntu',
  'Work Sans',
].map(fontName => { return { name: fontName, provider: 'google' } });

/* custom fonts */
const defaultCustomFonts = [
  {
    name: 'Open Dyslexic',
    url: 'fonts/default/OpenDyslexic/OpenDyslexic.css',
    provider: 'custom'
  },
  {
    name: 'Slabo',
    url: 'fonts/default/Slabo/Slabo.css',
    provider: 'custom'
  }
];

Vue.prototype.$fontManager = {

  loadedFonts: [],
  defaultFonts: [...defaultGoogleFonts, ...defaultCustomFonts],


  /**
   * Parse Font model and convert it to WebFontLoader format
   * @param {*} fontList
   */
  parseFontList(fontList) {
    let wflObj = {};

    for (let i = 0; i < fontList.length; i++) {
      if (!fontList[i].name || this.loadedFonts.includes(fontList[i].name)) {
        continue;
      }

      let options = '';
      if (fontList[i].provider !== 'custom') {
        if (fontList[i].styles && fontList[i].styles.length > 0) {
          options += ':' + fontList[i].styles.join(',');
        }
        if (fontList[i].subsets && fontList[i].subsets.length > 0) {
          options += ':' + fontList[i].subsets.join(',');
        }
      }

      switch (fontList[i].provider) {

        case 'typekit':
          if (!wflObj.typekit) {
            wflObj.typekit = {
              id: fontList[i].id + options,
              api: '//use.edgefonts.net'
            }
          } else {
            wflObj.typekit.id += ';' + fontList[i].name + options
          }
          break;

        case 'custom':
          let url = fontList[i].url;
          const pos = url.indexOf('amazonaws.com/');
          if (pos >= 0) {
            // bugfix previous upload fonts with aws s3 bucket inside url
            url = process.env.COMMON_AWS_S3_URL + url.substr(pos + 14);
          } else {
            url = process.env.COMMON_AWS_S3_URL + url;
          }

          if (!wflObj.custom) {
            wflObj.custom = {
              families: [fontList[i].name + options],
              urls: [url]
            }
          } else {
            wflObj.custom.families.push(fontList[i].name + options);
            wflObj.custom.urls.push(url);
          }
          break;

        case 'google':
        default:
          if (!wflObj.google) {
            wflObj.google = {
              families: [fontList[i].name + options]
            }
          } else {
            wflObj.google.families.push(fontList[i].name + options);
          }
          break;
      }
    }

    return wflObj;

  },

  loadFontList(fontList) {
    let filteredFontList = [...this.defaultFonts, ...fontList].filter(font => {
      return font.name && !this.loadedFonts.includes(font.name);
    });

    console.log("FontManager - loadFontList", filteredFontList);

    let wflObj = this.parseFontList(filteredFontList);

    // if no font has to be loaded, return
    if (Object.keys(wflObj).length === 0 && wflObj.constructor === Object) {
      return Promise.resolve();
    }

    let fontNames = filteredFontList.map(fnt => fnt.name);

    return new Promise((resolve, reject) => {
      WebFontLoader.load({
        ...wflObj,
        classes: false,
        active: (e) => {
          this.loadedFonts.push(...fontNames);
          console.log("FontManager - fonts is loaded", fontNames, e);
          resolve(fontNames);
        },
        inactive: (e) => {
          console.log("FontManager - error loading font", e)
          reject();
        }
      });
    })

  }
}