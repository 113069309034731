import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import Utils from '~/common/utils/store';

const emptyObject = {
  type: '',
  id: ''
};

const commonProps = {
  top: 0,
  left: 0,
  width: 0,
  height: 0,
  scaleX: 1,
  scaleY: 1,
  angle: 0,
  fill: '',
  stroke: null,
  strokeDashArray: null,
  strokeDashOffset: 0,
  strokeLineCap: 'butt',
  strokeLineJoin: 'miter',
  strokeWidth: 0,
  backgroundColor: '',
  paletteSwatchName: {},
  clipPath: null,
  clientSideLocked: false,
  adminSideLocked: false,
};
const textBoxProps = {
  textStyleKey: null,
  blockType: 'Body',
  fontFamily: '',
  fontWeight: 'normal',
  fontSize: 10,
  fontStyle: 'normal',
  underline: false,
  overline: false,
  linethrough: false,
  textBackgroundColor: '',
  deltaY: 0,

  lineHeight: 1,
  charSpacing: 1,
  dropCap: false,
  dropCapStyle: {},
  dropCapStyleKey: null,
  bulletPoint: false,
  bulletPointStyle: {},
  bulletPointStyleKey: null,
  endSign: false,
  endSignStyle: {},
  endSignStyleKey: null,
  textLink: false,
  textLinkStyle: {},
  textLinkStyleKey: null,
  textAlign: '',
  parentTextBoxId: '',
  childTextBoxId: '',
  legendImageId: '',
  backgroundPadding: { left: 0, right: 0, bottom: 0, top: 0 },
  textBackgroundPadding: { left: 0, right: 0, bottom: 0, top: 0 }
};
const dropCapProps = {
  dropCapHeight: 3,
  dropCapSpacing: 0,
  dropCapYOffset: 0,
  dropCapFontSizeOffset: 0,
  fill: '',
  fontFamily: '',
  fontStyle: 'normal',
  fontWeight: 'normal'
};
const bulletPointProps = {
  fill: '',
  character: ''
};
const endSignProps = {
  fill: '',
  character: ''
};
const textLinkProps = {
  fill: '',
  underline: false,
  fontStyle: 'normal',
  fontWeight: 'normal'
};

const imageProps = {
  cropX: 0,
  cropY: 0,
  src: '',
  legendTextBoxId: '',
  link: '',
};
const rectProps = {
  rx: 0,
  ry: 0,
};

function initialGui() {
  return {
    drawModeEnabled: false,
    gridEnabled: false,
    gridSize: 10,
    gridUnitMode: 1,
    rulerEnabled: false,
    snapEnabled: false,
    columnMagnetMargin: 10,
    marginAndColumnEnabled: true,
    columnMagnetEnabled: true,
    dimensionsExpanded: [],
    inpuNumberUnitMode: {
      default: 'px',
    }
  };
}

const getDefaultState = () => {
  return {
    canvas: {
      zoom: 100
    },
    gui: initialGui(),
    hasActiveObject: false,
    activeObject: {
      type: '',
      id: '',
      props: {}
    },
    selectionOfTypes: '',
    copiedObjects: []
  };
};

// initial state
const state = getDefaultState;

const mutations = {
  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state
   */
  UPDATE_ROOT_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state, value);
  },

  UPDATE_INPUT_NUMBER_UNIT_MODE: (state, { key, value }) => {
    // use Vue.set for add new key in inpuNumberUnitMode with reactivity
    Vue.set(state.gui.inpuNumberUnitMode, key, value);
  },

  TOGGLE_VALUE: (state, { key }) => {
    Utils.setProperty(key, state, !Utils.getProperty(key, state));
  },

  SET_ACTIVE_OBJECT: (state, { value }) => {
    if (!value || !value.id) {
      state.activeObject = emptyObject;
      state.hasActiveObject = false;
      return;
    }

    state.activeObject = {};
    state.activeObject.id = value.id;
    state.activeObject.type = value.type;
    state.hasActiveObject = true;
    state.selectionOfTypes = value.type;

    function getParamsByKey(key) {
      if (value.type === 'activeSelection' && !value[key]) {
        return value._objects[0][key];
        // const firstValue = JSON.stringify(value._objects[0][key]);
        // if (value._objects.every(o => JSON.stringify(o[key]) === firstValue)) {
        //   return value._objects[0][key];
        // } else {
        //   return undefined;
        // }
      } else {
        return value[key];
      }
    }

    if (value.type === 'activeSelection') {
      let firstType = value._objects[0].type;
      if (value._objects.every(o => o.type === firstType)) {
        state.selectionOfTypes = firstType;
      }
    }

    // assign common object props
    Object.keys(commonProps).forEach(key => {
      if (key === 'clipPath') {
        if (value[key]) {
          const clipPathObject = value[key].toObject();
          Vue.set(state.activeObject, key, {
            inverted: clipPathObject.inverted,
            nbObjects: clipPathObject.objects.length
          });
        } else {
          Vue.set(state.activeObject, key, null);
        }
      } else {
        Vue.set(state.activeObject, key, getParamsByKey(key));
      }
    });
    // assign textbox props
    if (state.selectionOfTypes === 'MzTextbox') {
      Object.keys(textBoxProps).forEach(key => {
        Vue.set(state.activeObject, key, getParamsByKey(key));
      });
      //dropcap Props
      const dropCapStyle = getParamsByKey('dropCapStyle');
      if (dropCapStyle) {
        Object.keys(dropCapProps).forEach(key => {
          Vue.set(state.activeObject.dropCapStyle, key, dropCapStyle[key]);
        });
      }
      //bulletPoint Props
      const bulletPointStyle = getParamsByKey('bulletPointStyle');
      if (bulletPointStyle) {
        Object.keys(bulletPointProps).forEach(key => {
          Vue.set(state.activeObject.bulletPointStyle, key, bulletPointStyle[key]);
        });
      }
      //endSign Props
      const endSignStyle = getParamsByKey('endSignStyle');
      if (endSignStyle) {
        Object.keys(endSignProps).forEach(key => {
          Vue.set(state.activeObject.endSignStyle, key, endSignStyle[key]);
        });
      }
      //textLink Props
      const textLinkStyle = getParamsByKey('textLinkStyle');
      if (textLinkStyle) {
        Object.keys(textLinkProps).forEach(key => {
          Vue.set(state.activeObject.textLinkStyle, key, textLinkStyle[key]);
        });
      }
    }

    if (value.type === 'MzImage') {
      Object.keys(imageProps).forEach(key => {
        Vue.set(state.activeObject, key, value[key]);
      });
      //exception for images, src may be accessible via getSrc()
      if (!value.src) {
        Vue.set(state.activeObject, 'src', value.getSrc());
      }
    }

    if (value.type === 'MzRect') {
      Object.keys(rectProps).forEach(key => {
        Vue.set(state.activeObject, key, value[key]);
      });
    }
  },
  SET_ACTIVE_OBJECT_PROP: (state, { key, value }) => {
    state.activeObject[key] = value;
  },
  CLEAR_ACTIVE_OBJECT: state => {
    state.activeObject = emptyObject;
    state.hasActiveObject = false;
  },
  ADD_COPIED_OBJECT: (state, object) => {
    // remove if previous copy already exist in list
    if (object.id) {
      const index = state.copiedObjects.find((o) => o.id == object.id);
      if (index) {
        state.copiedObjects.splice(index, 1);
      }
    }
    state.copiedObjects.push(object);
  },
  REMOVE_COPIED_OBJECT: (state, index) => {
    state.copiedObjects.splice(index, 1);
  },
  REMOVE_ALL_COPIED_OBJECTS: (state) => {
    state.copiedObjects = [];
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state to default value
   */
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  }
};
const actions = {};
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
