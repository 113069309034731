import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import api from './modules/api';
import format from './modules/storeFormat';
import magazine from './modules/storeMagazine';
import library from './modules/storeLibrary';
import page from './modules/storePage';
import theme from './modules/storeTheme';
import palette from './modules/storePalette';
import fabric from './modules/storeFabric';
import user from './modules/storeUser';
import article from './modules/storeArticle';
import collaboration from './modules/storeCollaboration';
import notification from './modules/storeNotification';
import offer from './modules/storeOffer';
import order from './modules/storeOrder';
import workspace from './modules/storeWorkspace';
import organization from './modules/storeOrganization';
import adminsettings from './modules/storeAdminSettings';
import authStrategy from './modules/storeAuthStrategy';
import tracking from './modules/storeTracking';

const createStore = () => {
  return new Vuex.Store({
    modules: {
      app,
      api,
      article,
      format,
      magazine,
      library,
      page,
      theme,
      palette,
      fabric,
      user,
      collaboration,
      notification,
      offer,
      order,
      workspace,
      organization,
      adminsettings,
      authStrategy,
      tracking
    },

    strict: process.env.VUE_NODE_ENV !== 'production'
  });
};

export default createStore;
